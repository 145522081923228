import {OptionTypeInterface} from '../interfaces/option-type.interface';

export const OPTION_TYPES: OptionTypeInterface[] =
    [
        {
            id: 'branding-7',
            name: 'OPTION.BRANDING-7',
            description: 'OPTION.BRANDING_DESCRIPTION',
            image: 'book_branding_true.png',
            available: true,
            values: []
        },
        {
            id: 'branding-4',
            name: 'OPTION.BRANDING-4',
            description: 'OPTION.BRANDING_DESCRIPTION',
            image: 'book_branding_true.png',
            available: false,
            values: []
        },
        {
            id: 'endleaf',
            name: 'OPTION.ENDLEAF',
            description: 'OPTION.ENDLEAF_DESCRIPTION',
            image: 'option_endleaf.png',
            available: true,
            values: []
        },
        {
            id: 'thread',
            name: 'OPTION.THREAD',
            description: 'OPTION.THREAD_DESCRIPTION',
            image: 'option_thread.png',
            available: true,
            values: []
        },
        {
            id: 'headband',
            name: 'OPTION.HEADBAND',
            description: 'OPTION.HEADBAND_DESCRIPTION',
            image: 'option_headband.png',
            available: true,
            values: []
        },
        {
            id: 'bookmark',
            name: 'OPTION.BOOKMARK',
            description: 'OPTION.BOOKMARK_DESCRIPTION',
            image: 'option_bookmark.png',
            available: true,
            values: []
        },
    ];