import {CoverModesEnum} from '../../../../../../../shared/enums/cover-modes.enum';
import {BookErrorInterface} from '../../../../../../../shared/interfaces/book-validate-return.interface';

export class SetProjectId {
    static readonly type = '[Configuration] Set project Id';

    constructor(public projectId: string) {
    }
}

export class SetCartId {
    static readonly type = '[Configuration] Set cart Id';

    constructor(public cartId: string) {
    }
}

export class SelectProductType {
    static readonly type = '[Configuration] Select Product Type';

    constructor(public productId: string) {
    }
}

export class SelectPaperFormat {
    static readonly type = '[Configuration] Select Paper Format';

    constructor(public formatId: string) {
    }
}

export class SelectBindingType {
    static readonly type = '[Configuration] Select Binding Type';

    constructor(public bindingId: string) {
    }
}

export class SelectColorMode {
    static readonly type = '[Configuration] Select Color Mode';

    constructor(public colorModeId: string) {
    }
}

export class SelectCoverMode {
    static readonly type = '[Configuration] Select Cover Mode';

    constructor(public coverModeId: CoverModesEnum) {
    }
}

export class SelectPaper {
    static readonly type = '[Configuration] Select Paper ';

    constructor(public paperId: string) {
    }
}

export class SelectPaperGroup {
    static readonly type = '[Configuration] Select Paper Group ';

    constructor(public paperGroupId: string) {
    }
}

export class SelectPaperSurface {
    static readonly type = '[Configuration] Select Paper Surface';

    constructor(public surfaceId: string) {
    }
}

export class SelectSurface {
    static readonly type = '[Configuration] Select Surface';

    constructor(public surfaceId: string) {
    }
}

export class SelectOption {
    static readonly type = '[Configuration] Select Options';

    constructor(public optionId: string, public optionValueId: string) {
    }
}

export class DeselectOption {
    static readonly type = '[Configuration] Deselect Options';

    constructor(public optionId: string) {
    }

}

export class SetQuantity {
    static readonly type = '[Summary] Set Quantity';

    constructor(public quantity: number) {
    }
}

export class SetProjectTitle {
    static readonly type = '[Configuration] Set ProjectTitle';

    constructor(public projectTitle: string) {
    }
}

export class SetUserConfiguration {
    static readonly type = '[Configuration] Set user configuration';

    constructor(public projectId: string) {
    }
}

export class SetIsCalculator {
  static readonly type = '[Configuration] Set Is Calculator';

  constructor(public isCalculator: boolean) {
  }
}
export class SelectTax {
    static readonly type = '[Configuration] Select tax';

    constructor(public tax: number) {
    }
}

export class ValidateBook {
    static readonly type = '[Configuration] Validate Book';
}

export class AddError {
    static readonly type = '[Configuration] Add Error';

    constructor(public error: BookErrorInterface) {
    }
}

export class RemoveError {

    static readonly type = '[Configuration] Remove Error';

    constructor(public errorId: string) {
    }
}


