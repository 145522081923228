import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'bubu-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss'],
})
export class NumberComponent implements OnInit {
    @Input()
    id: string;

    @Input()
    name: string;

    @Input()
    value ? = 1;

    @Input()
    min ? = 1;

    @Input()
    max ? = 10;

    @Input()
    step ? = 1;

    @Output()
    numberChanged: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
        this.fixTypes();
    }

    onChange(event: Event) {
        const inputNumber = Number((event.target as HTMLInputElement).value);
        this.value = inputNumber;
        this.fixTypes();
        if (inputNumber >= this.max) {
            this.value = this.max;
        }
        if (inputNumber <= this.min) {
            this.value = this.min;
        }
        (event.target as HTMLInputElement).value = this.value.toString();
        this.numberChanged.emit(this.value);
    }

    countUp() {
        this.fixTypes();
        this.step = Number(this.step);
        if (this.value + this.step <= this.max) {
            this.value += this.step;
            this.numberChanged.emit(this.value);
        }
    }

    countDown() {
        this.fixTypes();
        this.value = Number(this.value);
        if (this.value - this.step >= this.min) {
            this.value -= this.step;
            this.numberChanged.emit(this.value);
        }
    }

    private fixTypes() {
        this.value = Number(this.value);
        this.step = Number(this.step);
        this.max = Number(this.max);
        this.min = Number(this.min);
    }

}
