import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SummaryContainerComponent} from './containers/summary-container/summary-container.component';
import {SummaryRoutingModule} from './summary-routing.module';
import {SharedModule} from '../shared/shared.module';
import {ProjectTitleComponent} from './components/project-title/project-title.component';
import {ClipboardModule} from 'ngx-clipboard';


@NgModule({
    declarations: [SummaryContainerComponent, ProjectTitleComponent],
    imports: [
        ClipboardModule,
        CommonModule,
        SummaryRoutingModule,
        SharedModule,
    ],
})
export class SummaryModule {
}
