import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetQuantity } from '../../state/configuration/configuration.action';
import { ConfigurationState } from '../../state/configuration/configuration.state';

@Component({
  selector: 'bubu-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss']
})
export class QuantityComponent {

  quantity: number;
  @Input() isForCalculator = false;

  constructor(private readonly store: Store) {
    this.quantity = this.store.selectSnapshot(ConfigurationState.getQuantity)
      ? this.store.selectSnapshot(ConfigurationState.getQuantity) : 1;
  }

  setQuantity(event) {
    this.store.dispatch(new SetQuantity(event));
  }
}
