import { PageSizeInterface } from '../../../../../../shared/interfaces/page-size.interface';

export class IsCoverPDFSet {
  static readonly type = '[Cover] Set cover PDF state';

  constructor(public isCoverPDFSet: boolean) {
  }
}

export class IsCoverPDFOkay {
  static readonly type = '[Cover] Set cover PDF okay';

  constructor(public isCoverPDFOkay: boolean) {
  }
}

export class SetCoverPDFOriginalFilename {
  static readonly type = '[Cover] Set cover PDF original filename';

  constructor(public coverPDFOriginalFilename: string) {
  }
}

export class SetCoverPDFSize {
  static readonly type = '[Cover] Set cover PDF size';

  constructor(public coverPDFSize: PageSizeInterface) {
  }
}

export class SetCoverPDFDate {
  static readonly type = '[Cover] Set cover PDF date';

  constructor(public coverPDFDate: Date) {
  }
}

export class SetCoverPDFPageCount {
  static readonly type = '[Cover] Set PDF page count';

  constructor(public coverPDFPageCount: number) {
  }
}

export class SetCoverPDFErrors {
  static readonly type = '[Cover] Set Cover PDF errors';

  constructor(public coverPDFErrors: any[]) {
  }
}

export class SetCoverPDF {
  static readonly type = '[Cover] Set Cover PDF';

  constructor(public coverPDF: string) {
  }
}

