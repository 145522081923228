import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ConfigurationState } from '../state/configuration/configuration.state';

export class CoverModeInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.store.selectSnapshot(ConfigurationState.selectedCoverMode)) {
      const cloneReq = req.clone({
        setHeaders: {
          'x-cover-mode': this.store.selectSnapshot(ConfigurationState.selectedCoverMode).id
        }
      });
      return next.handle(cloneReq);
    }
    return next.handle(req);
  }
}
