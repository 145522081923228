import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ContentState} from '../../state/content.state';
import {Observable} from 'rxjs';
import {CoverModeInterface} from '../../interfaces/cover-mode.interface';
import {CoverModesEnum} from '../../../../../../../shared/enums/cover-modes.enum';
import {ConfigurationState} from '../../../shared/state/configuration/configuration.state';
import {SelectCoverMode} from '../../../shared/state/configuration/configuration.action';
import {ApiService} from '../../../shared/services/api.service';

@Component({
    selector: 'bubu-cover-mode',
    templateUrl: './cover-mode.component.html',
    styleUrls: ['./cover-mode.component.scss']
})
export class CoverModeComponent implements OnInit {

    @Select(ContentState.coverModes) coverModes$: Observable<CoverModeInterface[]>;
    selectedCoverMode: CoverModesEnum;

    constructor(private readonly store: Store,
                private apiService: ApiService) {
        this.selectedCoverMode =
            this.store.selectSnapshot(ConfigurationState.selectedCoverMode) ? this.store.selectSnapshot(ConfigurationState.selectedCoverMode).id : null;
    }

    async onCoverModeChanged(event: any) {
        this.store.dispatch(new SelectCoverMode(event.value));
    }


    ngOnInit() {
    }

}
