import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SampleFilesComponent} from '../shared/components/sample-files/sample-files.component';
import {InnerContainerComponent} from '../main/containers/inner-container/inner-container.component';
import {PriceComponent} from '../shared/components/price/price.component';
import {SelectionsComponent} from '../shared/components/selections/selections.component';
import {CoverContainerComponent} from './containers/cover-container/cover-container.component';
import {BookErrorGuard} from '../shared/guards/book-error.guard';


const coverRoutes: Routes = [
    {
        path: 'cover',
        canActivate: [BookErrorGuard],
        data: {bookErrorModules: ['paper', 'content']},
        component: InnerContainerComponent,
        children: [
            {
                path: '',
                component: CoverContainerComponent,
            },
            {
                path: '',
                outlet: 'additional-information',
                component: SelectionsComponent,
            },
            {
                path: '',
                outlet: 'additional-price',
                component: PriceComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(coverRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class CoverRoutingModule {
}
