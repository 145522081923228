import {ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {BookInterface} from '../../../../../../../shared/interfaces/book.interface';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {CoverState} from '../../state/cover.state';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {ConfigurationState} from '../../../shared/state/configuration/configuration.state';
import {b64toBlob} from '../../../shared/utils';
import {RemoveError, ValidateBook} from '../../../shared/state/configuration/configuration.action';
import {PageSizeInterface} from '../../../../../../../shared/interfaces/page-size.interface';
import {SetNextButtonEnabled} from '../../../shared/state/gui/app-config.actions';
import {BookErrorsEnum} from '../../../../../../../shared/enums/book-errors.enum';
import {CoverModesEnum} from '../../../../../../../shared/enums/cover-modes.enum';
import {ApiService} from '../../../shared/services/api.service';
import {
    IsCoverPDFSet, SetCoverPDF, SetCoverPDFDate, SetCoverPDFOriginalFilename,
    SetCoverPDFPageCount, SetCoverPDFSize
} from '../../state/cover.actions';
import {CoverModeInterface} from '../../../content/interfaces/cover-mode.interface';

@Component({
    selector: 'bubu-cover-container',
    templateUrl: './cover-container.component.html',
    styleUrls: ['./cover-container.component.scss']
})
export class CoverContainerComponent implements OnInit, OnDestroy {
    intent: INTENTS = INTENTS.COVER;
    sampleFileBook: Partial<BookInterface>;
    @Select(CoverState.isCoverPDFSet) isCoverPDFSet$: Observable<boolean>;
    @Select(CoverState.getCoverPDFErrors) coverPDFErrors$: Observable<any[]>;
    @Select(CoverState.getCoverPDF) coverPDF$: Observable<string>;
    @Select(CoverState.getCoverPDFPageCount) coverPDFPageCount$: Observable<number>;
    @Select(CoverState.getCoverPDFSize) coverPDFSize$: Observable<PageSizeInterface>;
    @Select(ConfigurationState.getProjectId) projectId$: Observable<string>;
    @Select(ConfigurationState.selectedCoverMode) selectedCoverMode$: Observable<CoverModeInterface>;
    @ViewChild('pdfViewerAutoLoad', {static: true}) pdfViewerAutoLoad;

    hasErrors = false;

    pdfUploaded = false;

    coverPDFSpecs = [
        'COVER.CUT',
        'CONTENT.NO_PRINT_MARKS'
    ];

    private coverPDFSubscription: Subscription;
    private coverPDFPageCountSubscription: Subscription;
    private coverPdfSizeSubscription: Subscription;

    constructor(private readonly store: Store,
                private cdRef: ChangeDetectorRef,
                private readonly apiService: ApiService) {
    }

    async ngOnInit() {
        const configuredBook = this.store.selectSnapshot(ConfigurationState.getBook);
        this.sampleFileBook = {
            ...configuredBook
        };
        this.coverPDFSubscription = this.coverPDF$.subscribe(pdfData => {
            if (pdfData) {
                this.store.dispatch(new RemoveError(BookErrorsEnum.COVER_PDF_MISSING));
            }

        });

        this.coverPDFPageCountSubscription = this.coverPDFPageCount$.subscribe(pageCount => {
            if (pageCount) {
                this.store.dispatch(new RemoveError(BookErrorsEnum.COVER_PDF_MISSING));
                this.store.dispatch(new ValidateBook());

            }
        });

        this.coverPdfSizeSubscription = this.coverPDFSize$.subscribe(size => {
            if (size) {
                this.store.dispatch(new ValidateBook());

            }
        });

        if (this.store.selectSnapshot(CoverState.isCoverPDFSet)) {
            this.pdfUploaded = true;
            this.store.dispatch(new RemoveError(BookErrorsEnum.COVER_PDF_MISSING));
        }

        switch (this.store.selectSnapshot(ConfigurationState.selectedCoverMode).id) {
            case CoverModesEnum.ONE_FILE:
                // get informations for separated pdf
                const result = (await this.apiService.getCoverModeSeparatedFile(
                    this.store.selectSnapshot(ConfigurationState.getProjectId)
                ).toPromise()) as any;
                if (result) {
                    // set the file created in the backend as cover
                    this.store.dispatch([
                        new SetCoverPDFOriginalFilename(result.filename),
                        new SetCoverPDFDate(new Date()),
                        new IsCoverPDFSet(true),
                        new SetCoverPDFPageCount(result.pageCount),
                        new SetCoverPDFSize({
                            height: result.size.height,
                            width: result.size.width
                        }),
                        new SetCoverPDF(''),
                    ]);
                    this.store.dispatch(new RemoveError(BookErrorsEnum.COVER_PDF_MISSING));
                }
                break;
        }
    }

    setErrors(errors) {
        this.hasErrors = errors.length > 0;
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        this.coverPDFSubscription.unsubscribe();
        this.coverPDFPageCountSubscription.unsubscribe();
        this.coverPdfSizeSubscription.unsubscribe();
    }

}
