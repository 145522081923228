import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContentState } from '../../state/content.state';
import { ColorModeInterface } from '../../interfaces/color-mode.interface';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SelectColorMode } from '../../../shared/state/configuration/configuration.action';
import { BookContentColor } from '../../../../../../../shared/enums/book-content-color.enum';

@Component({
  selector: 'bubu-color-mode',
  templateUrl: './color-mode.component.html',
  styleUrls: ['./color-mode.component.scss']
})
export class ColorModeComponent implements OnInit {


  @Select(ContentState.colorModes) colorModes$: Observable<ColorModeInterface[]>;
  selectedColorMode: BookContentColor;

  constructor(private readonly store: Store) {
    this.selectedColorMode =
      this.store.selectSnapshot(ConfigurationState.selectedColorMode) ? this.store.selectSnapshot(ConfigurationState.selectedColorMode).id : null;
  }

  onColorModeChanged(event: any) {
    this.store.dispatch(new SelectColorMode(event.value));
  }

  ngOnInit() {
  }

}
