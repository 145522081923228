import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {ApiService} from '../../shared/services/api.service';
import {PRODUCT_TYPES} from '../configs/product-types';
import {PaperFormatInterface} from '../interfaces/paper-format.interface';
import {ProductTypeInterface} from '../interfaces/product-type.interface';
import {ProductStateModel} from './product-state.model';
import {SetPaperFormats} from './product.actions';
import {ConfigurationState} from '../../shared/state/configuration/configuration.state';
import {BookConfigurationsService} from '../../../../../../shared/services/book-configurations.service';

const defaultProductState = (): ProductStateModel => {
    return <ProductStateModel>{
        productTypes: PRODUCT_TYPES,
        paperFormats: [],
        allowedFormats: {}
    };
};

@State<ProductStateModel>({
    name: 'productState',
    defaults: defaultProductState(),
})

export class ProductState implements NgxsOnInit {
    static store: Store;

    constructor(private apiService: ApiService, private readonly store: Store) {
        ProductState.store = store;
    }

    @Selector()
    static productTypes(state: ProductStateModel): ProductTypeInterface[] {
        return state.productTypes;
    }


    @Selector()
    static paperFormats(state: ProductStateModel): PaperFormatInterface[] {
        return state.paperFormats;
    }

    @Selector([ConfigurationState])
    static filteredProducts(state: ProductStateModel): PaperFormatInterface[] {
        const paperFormatsFiltered: PaperFormatInterface[] = [];
        const selectedProductType = ProductState.store.selectSnapshot(ConfigurationState.selectedProductType);
        if (state.allowedFormats.hasOwnProperty(selectedProductType.id)) {
            state.paperFormats.forEach(paperFormat => {
                if (state.allowedFormats[selectedProductType.id].includes(paperFormat.id)) {
                    paperFormatsFiltered.push(paperFormat);
                }
            });
            return paperFormatsFiltered;
        } else {
            return [];
        }
    }

    ngxsOnInit({dispatch}: StateContext<ProductStateModel>): void | any {
        dispatch(new SetPaperFormats());
    }


    @Action(SetPaperFormats)
    setPaperFormats(context: StateContext<ProductStateModel>) {
        const data = BookConfigurationsService.getFormatConfig();
        return context.patchState({
            paperFormats: this.buildPaperFormatArray(data),
            allowedFormats: this.buildAllowedFormatsArray(data),
        });
    }

    private buildPaperFormatArray(paperFormatsRaw: any): PaperFormatInterface[] {
        const returnArray: PaperFormatInterface[] = [];
        for (let format in paperFormatsRaw.definitions as object) {
            returnArray.push({
                id: format,
                name: 'FORMAT.' + format.toUpperCase(),
                width: paperFormatsRaw.definitions[format][0],
                height: paperFormatsRaw.definitions[format][1],
            });
        }
        return returnArray;
    }

    private buildAllowedFormatsArray(availables: any): any {
        const returnObject = {};
        for (const available in availables.available as object) {
            switch (available) {
                case 'hang':
                    returnObject['hang'] = Array.from(this.getFormatsFromConfigStructure(available, availables));
                    break;
                case 'fixing':
                    returnObject['fixing'] = Array.from(this.getFormatsFromConfigStructure(available, availables));
                    break;
                case 'stitch':
                    returnObject['stitch'] = Array.from(this.getFormatsFromConfigStructure(available, availables));
                    break;
                case 'wireo':
                    returnObject['wireo'] = Array.from(this.getFormatsFromConfigStructure(available, availables));

                    break;
            }
        }

        return returnObject;
    }

    private getFormatsFromConfigStructure(available, availables): any {
        const tempSet = new Set();
        for (const bindings in availables.available[available]) {
            availables.available[available][bindings].forEach(paperFormat => tempSet.add(paperFormat));
        }
        return tempSet;
    }
}
