import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceCalculatorComponent } from './components/price-calculator/price-calculator.component';
import { SharedModule } from '../shared/shared.module';
import { ProductModule } from '../product/product.module';
import { BindingModule } from '../binding/binding.module';
import { PaperModule } from '../paper/paper.module';
import { ContentModule } from '../content/content.module';
import { SurfaceModule } from '../surface/surface.module';
import { OptionsModule } from '../options/options.module';
import { MainModule } from '../main/main.module';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  declarations: [PriceCalculatorComponent],
  exports: [
    PriceCalculatorComponent
  ],
  imports: [
    BindingModule,
    ClipboardModule,
    CommonModule,
    ProductModule,
    SharedModule,
    PaperModule,
    ContentModule,
    SurfaceModule,
    OptionsModule,
    MainModule
  ]
})
export class PriceCalculatorModule {
}
