import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bubu-info-list-component',
  templateUrl: './info-list-component.component.html',
  styleUrls: ['./info-list-component.component.scss']
})
export class InfoListComponentComponent implements OnInit {
  @Input()
  listItems: string[];

  constructor() { }

  ngOnInit() {
  }

}
