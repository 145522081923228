import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PopUpDialogComponent, PopUpDialogType} from '../pop-up-dialog/pop-up-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'bubu-mobile-detection-dialog',
    templateUrl: './mobile-detection-dialog.component.html',
    styleUrls: ['./mobile-detection-dialog.component.scss']
})
export class MobileDetectionDialogComponent implements OnInit {

    constructor(public dialog: MatDialog,
                private deviceService: DeviceDetectorService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();

        if (isMobile || isTablet) {
            this.showDialog();
        }
    }

    showDialog() {

        const dialogRef = this.dialog.open(PopUpDialogComponent, {
            width: '650px',
            data: {
                title: 'COMMON.MOBILE_DETECTION_TITLE',
                text: 'COMMON.MOBILE_DETECTION_TEXT',
                textYes: 'COMMON.OK',
                type: PopUpDialogType.ALERT
            }
        });

        dialogRef.afterClosed().subscribe(async () => {
            window.location.href = this.translate.instant('COMMON.MOBILE_DETECTION_REDIRECT_URL');
        });
    }
}
