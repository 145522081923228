import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { ConfigurationState } from '../../shared/state/configuration/configuration.state';
import { Observable } from 'rxjs';
import { PaperFormatInterface } from '../../product/interfaces/paper-format.interface';
import { ProductTypeInterface } from '../../product/interfaces/product-type.interface';
import { BindingTypeInterface } from '../../binding/interfaces/binding-type.interface';
import { PaperInterface } from '../../paper/interfaces/paper.interface';
import { PaperGroupInterface } from '../../paper/interfaces/paper-group.interface';
import { PaperSurfaceInterface } from '../../paper/interfaces/paper-surface.interface';
import { SurfaceTypeInterface } from '../../surface/interfaces/surface-type.interface';
import { OptionInterface } from '../../../../../../shared/interfaces/option.interface';
import { ColorModeInterface } from '../../content/interfaces/color-mode.interface';
import { ContentState } from '../../content/state/content.state';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PriceState } from '../../shared/state/price/price.state';
import { Price } from '../../../../../../shared/classes/price';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  maax: {
    normal: `${window.location.origin}/assets/fonts/maax-webfont.ttf`,
    bold: `${window.location.origin}/assets/fonts/maax-bold-webfont.ttf`,
  },
  Roboto: {
    normal: `${window.location.origin}/assets/fonts/maax-webfont.ttf`,
    bold: `${window.location.origin}/assets/fonts/maax-bold-webfont.ttf`,
  }
};

@Injectable({
  providedIn: 'root'
})
export class OfferPdfService {
  @Select(ConfigurationState.selectedPaperFormat) selectedPaperFormat$: Observable<PaperFormatInterface>;
  @Select(ConfigurationState.selectedProductType) selectedProductType$: Observable<ProductTypeInterface>;
  @Select(ConfigurationState.selectedBinding) selectedBinding$: Observable<BindingTypeInterface>;
  @Select(ConfigurationState.selectedPaper) selectedPaper$: Observable<PaperInterface>;
  @Select(ConfigurationState.selectedPaperGroup) selectedPaperGroup$: Observable<PaperGroupInterface>;
  @Select(ConfigurationState.selectedSurface) selectedSurface$: Observable<PaperSurfaceInterface>;
  @Select(ConfigurationState.selectedSurfaceType) selectedSurfaceType$: Observable<SurfaceTypeInterface>;
  @Select(ConfigurationState.selectedOptions) selectedOptions$: Observable<OptionInterface[]>;
  @Select(ConfigurationState.selectedColorMode) selectedColorMode$: Observable<ColorModeInterface>;
  @Select(ContentState.getContentPDFPageCount) pages$: Observable<number>;
  @Select(ConfigurationState.getQuantity) quantity$: Observable<number>;
  @Select(ConfigurationState.selectedTax) tax$: Observable<number>;
  @Select(PriceState.getPrice) price$: Observable<Price>;

  selectedPaperFormat: PaperFormatInterface;
  selectedProductType: ProductTypeInterface;
  selectedBinding: BindingTypeInterface;
  selectedPaper: PaperInterface;
  selectedPaperGroup: PaperGroupInterface;
  selectedSurface: PaperSurfaceInterface;
  selectedSurfaceType: SurfaceTypeInterface;
  selectedOptions: string;
  selectedColorMode: ColorModeInterface;
  pages: number;
  quantity: number;
  tax: number;
  price: Price;

  constructor(private translate: TranslateService,
              private readonly http: HttpClient,
  ) {


    this.selectedPaperFormat$.subscribe(data => this.selectedPaperFormat = data);
    this.selectedProductType$.subscribe(data => this.selectedProductType = data);
    this.selectedBinding$.subscribe(data => this.selectedBinding = data);
    this.selectedPaper$.subscribe(data => this.selectedPaper = data);
    this.selectedPaperGroup$.subscribe(data => this.selectedPaperGroup = data);
    this.selectedSurface$.subscribe(data => this.selectedSurface = data);
    this.selectedSurfaceType$.subscribe(data => this.selectedSurfaceType = data);
    this.selectedOptions$.subscribe(data => {
      this.selectedOptions = '';
      data.forEach(selectedOption => {
        this.selectedOptions += `${this.translate.instant('OPTION.' + selectedOption.name.toUpperCase())} (${this.translate.instant('OPTION.VALUE.' + selectedOption.selected.toUpperCase())}), `;
      });
      // remove last comma
      this.selectedOptions = this.selectedOptions.slice(0, -2);
    });
    this.selectedColorMode$.subscribe(data => this.selectedColorMode = data);
    this.quantity$.subscribe(data => this.quantity = data);
    this.pages$.subscribe(data => this.pages = data);
    this.tax$.subscribe(data => this.tax = data);
    this.price$.subscribe(data => this.price = data);
  }

  async getFile(ding: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.readAsDataURL(ding);
    });
  }

  async generatePDF() {
    const res = await this.http.get('/assets/images/bookfactory_logo.png', { responseType: 'blob' }).toPromise();
    const image = await this.getFile(res);

    let priceDiscount = {};
    if (this.price.discountValue > 0) {
      priceDiscount = {
        columns: [
          {
            width: 50,
            text: `${this.translate.instant('PRICE.DISCOUNT')}`,
            lineHeight: 1.5,
          },
          {
            width: 20,
            text: 'CHF'
          },
          {
            width: 60,
            text: '-' + this.price.discountValue.toLocaleString(),
            alignment: 'right'
          }
        ],
        columnGap: 10,
      };
    }
    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [{
        columns: [
          {
            image,
            width: 250,
          }, {
            width: 'auto',
            text: [
              {
                text: 'Bookfactory\n\nIsenrietstrasse 21\n8617 Mönchaltorf\nSwitzerland\n+41 44 949 44 99\n\nservice@bookfactory.ch\nwww.bookfactory.ch',
                style: 'margin',
                fontSize: 8,
                bold: true
              }
            ]
          }
        ],
        columnGap: 100,
      }, {
        text: this.translate.instant('PDF.OFFER'),
        margin: [0, 90, 0, 10],
        fontSize: 12,
        bold: true
      },
        {
          text: this.translate.instant('PDF.MAIN_TEXT'),
          margin: [0, 10, 0, 20],
        },
        {
          text: [
            {
              text: `${this.translate.instant('TYPE.PRODUCT_TYPE')}: `,
              style: 'header',
            }, {
              text: `${this.translate.instant(this.selectedProductType.name)}\n`,
            },
            {
              text: `${this.translate.instant('TYPE.FORMAT')}: `,
              style: 'header'
            }, `${this.translate.instant(this.selectedPaperFormat.name)}\n`,
            {
              text: `${this.translate.instant('BINDING.BINDING')}: `,
              style: 'header'
            }, `${this.translate.instant(this.selectedBinding.name)}\n`,
            {
              text: `${this.translate.instant('PAPER.PAPER')}: `,
              style: 'header'
            }, `${this.translate.instant(this.selectedPaperGroup.name)}\n`,
            {
              text: `${this.translate.instant('COMMON.GRAMMAR')}: `,
              style: 'header'
            }, `${this.decodeString(this.translate.instant(this.selectedPaper.name))}\n`,
            {
              text: `${this.translate.instant('CONTENT.COLOR_MODE')}: `,
              style: 'header'
            }, `${this.translate.instant(this.selectedColorMode.name)}\n`,
            {
              text: `${this.translate.instant('SURFACE.SURFACE')}: `,
              style: 'header'
            }, `${this.translate.instant(this.selectedSurface.name)}\n`,
            {
              text: `${this.translate.instant('OPTION.OPTIONS')}: `,
              style: 'header'
            }, `${this.selectedOptions}\n`,
            {
              text: `${this.translate.instant('QUANTITY.TITLE')} `,
              style: 'header'
            }, `${this.quantity}\n`,
            {
              text: `${this.translate.instant('COMMON.PAGE_COUNT')}: `,
              style: 'header'
            }, `${this.pages}\n`,
            {
              text: `${this.translate.instant('PRICE.VAT')}: `,
              style: 'header'
            }, `${this.tax.toLocaleString()}%\n\n`,
          ]
        },
        {
          canvas: [{ type: 'line', x1: 0, y1: 0, x2: 160, y2: 0, lineWidth: 1 }],
          margin: [0, 2, 0, 5],
        },
        {
          columns: [
            {
              width: 50,
              text: `${this.translate.instant('PRICE.SUM')}`,
              lineHeight: 1.5,
            },
            {
              width: 20,
              text: 'CHF'
            },
            {
              width: 60,
              text: this.price.net.toLocaleString(),
              alignment: 'right'
            }
          ],
          columnGap: 10,
        },
        { ...priceDiscount },
        {
          columns: [
            {
              width: 50,
              text: `${this.price.taxRate} ${this.translate.instant('PRICE.VAT')}`,
              lineHeight: 1.5,
            },
            {
              width: 20,
              text: 'CHF'
            },
            {
              width: 60,
              text: this.price.taxValue.toLocaleString(),
              alignment: 'right'
            }
          ],
          columnGap: 10,
        },
        {
          canvas: [{ type: 'line', x1: 0, y1: 0, x2: 160, y2: 0, lineWidth: 1 }],
          margin: [0, 2, 0, 5],
        },
        {
          columns: [
            {

              width: 50,
              text: `${this.translate.instant('PRICE.TOTAL')}`,
              bold: true,
              lineHeight: 1.5,
            },
            {

              width: 21,
              text: 'CHF',
              bold: true
            },
            {

              width: 60,
              text: this.price.gross.toLocaleString(),
              bold: true,
              alignment: 'right'
            }
          ],
          columnGap: 10,
        }, {
          text: 'Bubu AG | Isenrietstrasse 21 | CH-8617 Mönchaltorf | MwSt 225 680 \n' +
            'Bookfactory | service@bookfactory.ch | www.bookfactory.ch | Telefon 044 949 44 99 ',
          alignment: 'center',
          absolutePosition: { x: 0, y: 760 },
          color: '#949699',
          fontSize: 9
        }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          lineHeight: 1.5,
        },
        margin: {
          columnGap: 20
        }
      },
      defaultStyle: {
        font: 'maax',
        fontSize: 10,
      }

    };
    pdfMake.createPdf(documentDefinition).download(this.translate.instant('PDF.FILENAME'));
  }

  private decodeString(text: string) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(
      '<!doctype html><body>' + text,
      'text/html');
    return dom.body.textContent;
  }

}
