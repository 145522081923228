export class Price {
    amount: number;
    discountPercentage?: number;
    discountValue?: number;
    gross: number;
    net: number;
    taxRate: number;
    taxValue?: number;

    constructor(amount: number, discountPercentage: number, net: number, taxRate: number) {
        this.amount = amount;
        this.discountPercentage = discountPercentage;
        this.net = net;
        this.taxRate = taxRate;
    }

    calculate() {
        this.net *= this.amount;
        this.net = this.round(this.net);
        this.discountValue = this.net / 100 * this.discountPercentage;
        this.discountValue = this.round(this.discountValue);
        this.net -= this.discountValue;
        this.taxValue = this.net / 100 * this.taxRate;
        this.taxValue = this.round(this.taxValue);
        this.gross = this.net + this.taxValue;
        this.gross = Math.round(this.round(this.gross) * 20) / 20; // schweizer rappenrundung
    }

    private round(price: number): number {
        price = Math.round(price * 10000) / 10000;
        price = Math.round(price * 100) / 100;
        return price;
    }
}
