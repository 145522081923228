import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProductTypesListComponent } from './components/product-types-list/product-types-list.component';
import { PaperFormatComponent } from './components/paper-format/paper-format.component';
import { ProductContainerComponent } from './containers/product-container/product-container.component';
import { ProductRoutingModule } from './product-routing.module';


@NgModule({
  declarations: [ProductContainerComponent, ProductTypesListComponent, PaperFormatComponent],
  exports: [PaperFormatComponent, ProductTypesListComponent],
  imports: [
    CommonModule,
    ProductRoutingModule,
    SharedModule,
  ],
})
export class ProductModule {
}
