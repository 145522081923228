import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaperState } from '../../state/paper.state';
import { PaperSurfaceInterface } from '../../interfaces/paper-surface.interface';
import { SelectPaperSurface } from '../../../shared/state/configuration/configuration.action';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';

@Component({
  selector: 'bubu-paper-list',
  templateUrl: './paper-list.component.html',
  styleUrls: ['./paper-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaperListComponent implements AfterViewInit, AfterViewChecked {
  @Select(PaperState.filteredSurfaces) surfaces$: Observable<PaperSurfaceInterface[]>;
  @Select(ConfigurationState.selectedSurface) selectedSurface$: Observable<PaperSurfaceInterface>;

  @Input() showDropDownPaperlist = false;
  selectedSurface: string;
  @ViewChild('paperListRow', { static: false }) paperListRow: ElementRef;
  @ViewChildren('paperListColumn') paperListColumn: QueryList<ElementRef>;
  parentRowWidth = 0;
  childIndex = [];


  constructor(private readonly store: Store) {
    this.selectedSurface =
      this.store.selectSnapshot(ConfigurationState.selectedSurface) ?
        this.store.selectSnapshot(ConfigurationState.selectedSurface).id : null;

  }

  onSurfaceChanged(event: string) {
    this.selectedSurface = event;
    this.store.dispatch(new SelectPaperSurface(event));
  }

  ngAfterViewInit(): void {
  }


  ngAfterViewChecked(): void {
    if (!this.showDropDownPaperlist) {
      this.parentRowWidth = this.paperListRow.nativeElement.offsetWidth;

      // remove padding from width else the element is to far in the sidepanel
      if (this.paperListRow.nativeElement.children[0]) {
        this.parentRowWidth -= Number(
          window.getComputedStyle(
            this.paperListRow.nativeElement.children[0]
          ).getPropertyValue('padding-right')
            .replace('px', ''))
          * 2;
      }

      this.paperListColumn.forEach((child, index) => {
        this.childIndex[index] = -child.nativeElement.offsetLeft;
      });
    }
  }
}
