import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from '../../shared/services/api.service';
import { ContentStateModel } from './content-state.model';
import { COLOR_MODES } from '../config/color-modes';
import { ColorModeInterface } from '../interfaces/color-mode.interface';
import { COVER_MODES } from '../config/cover-modes';
import { CoverModeInterface } from '../interfaces/cover-mode.interface';
import {
  IsContentPDFOkay,
  IsContentPDFSet,
  SetContentPDF,
  SetContentPDFDate,
  SetContentPDFErrors,
  SetContentPDFOriginalFilename,
  SetContentPDFPageCount,
  SetContentPDFSize,
  SetSamplePageCount
} from './content.actions';
import { PageSizeInterface } from '../../../../../../shared/interfaces/page-size.interface';

const defaultContentState = (): ContentStateModel => {
  return <ContentStateModel>{
    colorModes: COLOR_MODES,
    contentPDFSet: false,
    contentPDFOk: null,
    contentPDFOriginalFilename: null,
    contentPDFSize: null,
    contentPDFDate: null,
    coverModes: COVER_MODES,
    samplePageCount: 16,
    contentPDF: null,
    contentPDFPageCount: 1,
    contentPDFErrors: []
  }
};

@State<ContentStateModel>({
  name: 'contentState',
  defaults: defaultContentState(),
})

export class ContentState implements NgxsOnInit {
  constructor(private apiService: ApiService) {
  }


  @Selector()
  static colorModes(state: ContentStateModel): ColorModeInterface[] {
    return state.colorModes;
  }


  @Selector()
  static coverModes(state: ContentStateModel): CoverModeInterface[] {
    return state.coverModes;
  }

  @Selector()
  static isContentPDFSet(state: ContentStateModel): boolean {
    return state.contentPDFSet;
  }

  @Selector()
  static getContentPDFSize(state: ContentStateModel): PageSizeInterface {
    return state.contentPDFSize;
  }

  @Selector()
  static getContentPDFDate(state: ContentStateModel): Date {
    return state.contentPDFDate;
  }

  @Selector()
  static getContentPDFOriginalFileName(state: ContentStateModel): string {
    return state.contentPDFOriginalFilename;
  }

  @Selector()
  static getSamplePageCount(state: ContentStateModel): number {
    return state.samplePageCount;
  }

  @Selector()
  static getContentPDFErrors(state: ContentStateModel): any[] {
    return state.contentPDFErrors;
  }

  @Selector()
  static getContentPDFPageCount(state: ContentStateModel): number {
    return state.contentPDFPageCount;
  }

  @Selector()
  static getContentPDF(state: ContentStateModel): string {
    return state.contentPDF;
  }


  @Action(IsContentPDFSet)
  setIsContentPDF(context: StateContext<ContentStateModel>, { isContentPDFSet }: IsContentPDFSet
  ) {
    return context.patchState({ contentPDFSet: isContentPDFSet });
  }

  @Action(IsContentPDFOkay)
  setIsContentPDFOkay(context: StateContext<ContentStateModel>, { isContentPDFOkay }: IsContentPDFOkay
  ) {
    return context.patchState({ contentPDFOk: isContentPDFOkay });
  }

  @Action(SetContentPDFOriginalFilename)
  setContentPDFOriginalFilename(context: StateContext<ContentStateModel>, { contentPDFOriginalFilename }: SetContentPDFOriginalFilename
  ) {
    return context.patchState({ contentPDFOriginalFilename: contentPDFOriginalFilename });
  }

  @Action(SetContentPDFSize)
  setContentPDFSize(context: StateContext<ContentStateModel>, { contentPDFSize }: SetContentPDFSize
  ) {
    return context.patchState({ contentPDFSize });
  }

  @Action(SetContentPDFDate)
  setContentPDFDate(context: StateContext<ContentStateModel>, { contentPDFDate }: SetContentPDFDate
  ) {
    return context.patchState({ contentPDFDate });
  }

  @Action(SetSamplePageCount)
  setSamplePageCount(context: StateContext<ContentStateModel>, { samplePageCount }: SetSamplePageCount
  ) {
    return context.patchState({ samplePageCount });
  }


  @Action(SetContentPDFPageCount)
  setContentPDFPageCount(context: StateContext<ContentStateModel>, { contentPDFPageCount }: SetContentPDFPageCount
  ) {
    return context.patchState({ contentPDFPageCount: contentPDFPageCount });
  }

  @Action(SetContentPDFErrors)
  setContentPDFErrors(context: StateContext<ContentStateModel>, { contentPDFErrors }: SetContentPDFErrors
  ) {
    return context.patchState({ contentPDFErrors });
  }

  @Action(SetContentPDF)
  setContentPDF(context: StateContext<ContentStateModel>, { contentPDF }: SetContentPDF
  ) {
    return context.patchState({ contentPDF });
  }

  ngxsOnInit({ dispatch }: StateContext<ContentState>): void | any {
    // dispatch(new SetPaperFormats());
  }


}
