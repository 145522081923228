import { Injectable } from '@angular/core';
import { BookErrorComponent } from '../components/book-error/book-error.component';
import { ConfigurationState } from '../state/configuration/configuration.state';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class RouteServiceService {

  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {
  }

  checkIfRouteCanBeActivated(route: string): boolean {
    const routeConfig = this.router.config.find(singleRoute => singleRoute.path === route).data;
    if (routeConfig !== undefined) {
      if (routeConfig.hasOwnProperty('bookErrorModules')) {
        return BookErrorComponent.filter(routeConfig.bookErrorModules, this.store.selectSnapshot(ConfigurationState.getErrors)).length === 0;
      }
    }
    return true;
  }
}
