import {BookErrorsEnum} from '../enums/book-errors.enum';

export interface BookModuleErrors {

    id: string;
    errors: BookErrorsEnum[];

}

export const BOOK_MODULE_ERRORS: BookModuleErrors[] =
    [
        {
            id: 'binding',
            errors: [
                BookErrorsEnum.INVALID_BINDING,
                BookErrorsEnum.INVALID_FORMAT
            ]
        },
        {
            id: 'paper',
            errors: [
                BookErrorsEnum.INVALID_CONTENT_PAPER,
                BookErrorsEnum.CONTENT_MAX_THICKNESS_EXCEEDED
            ]
        },
        {
            id: 'content',
            errors: [
                BookErrorsEnum.CONTENT_MAX_THICKNESS_EXCEEDED,
                BookErrorsEnum.TOO_LESS_CONTENT_PAGES,
                BookErrorsEnum.TOO_MUCH_CONTENT_PAGES,
                BookErrorsEnum.CONTENT_PDF_MISSING,
                BookErrorsEnum.INVALID_CONTENT_PAGE_STEPS,
                BookErrorsEnum.CONTENT_PDF_SIZE_WRONG,
            ]
        },
        {
            id: 'cover',
            errors: [
                BookErrorsEnum.INVALID_COVER_PAGES,
                BookErrorsEnum.COVER_PDF_MISSING,
                BookErrorsEnum.COVER_PDF_SIZE_WRONG,
            ]
        },
        {
            id: 'all',
            errors: [
                BookErrorsEnum.INVALID_MONTAGE,
                BookErrorsEnum.INVALID_BINDING,
                BookErrorsEnum.TOO_MUCH_CONTENT_PAGES,
                BookErrorsEnum.TOO_LESS_CONTENT_PAGES,
                BookErrorsEnum.INVALID_CONTENT_PAGE_STEPS,
                BookErrorsEnum.CONTENT_MAX_THICKNESS_EXCEEDED,
                BookErrorsEnum.INVALID_SURFACE,
                BookErrorsEnum.INVALID_CONTENT_PAPER,
                BookErrorsEnum.INVALID_FORMAT,
                BookErrorsEnum.INVALID_OPTION,
                BookErrorsEnum.MISSING_OPTION,
                BookErrorsEnum.INVALID_OPTION_VALUE,
                BookErrorsEnum.INVALID_COLOR,
                BookErrorsEnum.INVALID_TAX_RATE,
                BookErrorsEnum.INVALID_COVER_PAGES,
                BookErrorsEnum.CONTENT_PDF_SIZE_WRONG,
                BookErrorsEnum.COVER_PDF_SIZE_WRONG,
            ]
        },
    ];
