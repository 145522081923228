import { Component, ElementRef, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { CanColor, ThemePalette } from './color';
import { CanDisable } from './disabled';

@Component({
  selector: `button[bubu-button]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[attr.disabled]': 'disabled || null',
  },
})
export class BubuButtonComponent implements OnInit, CanColor, CanDisable {
  @Input()
  color: ThemePalette = 'primary';
  @Input()
  disabled: boolean;
  @Input()
  small = false;

  constructor(private _elementRef: ElementRef) {
    _elementRef.nativeElement.classList.add('bubu-button-base');
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(): void {
    this._elementRef.nativeElement.classList.add('bubu-button-mouse-down');
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(): void {
    this._elementRef.nativeElement.classList.remove('bubu-button-mouse-down');
  }

  ngOnInit() {
    this._elementRef.nativeElement.classList.add(`bubu-button-${this.color}`);
    if (this.small) {
      this._elementRef.nativeElement.classList.add('small');
    }
  }


}
