import { TranslateService } from '@ngx-translate/core';
import { Action, NgxsAfterBootstrap, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { AppConfigStateModel } from './app-config-state.model';
import {
    SetAppVersion,
    SetBackendConfig,
    SetCartButtonEnabled,
    SetCartButtonVisible,
    SetLanguage,
    SetNextButtonEnabled
} from './app-config.actions';
import { MAIN_MENU_ENTRIES, MainMenuEntriesInterface } from './config/main-menu-entries';
import { ApiService } from '../../services/api.service';
import { tap } from 'rxjs/operators';

const defaultGuiState = (): AppConfigStateModel => {
    return <AppConfigStateModel>{
        availableLanguages: ['de', 'en', 'fr'],
        backendConfig: {},
        selectedLanguage: 'de',
        mainMenuEntries: MAIN_MENU_ENTRIES,
        cartButtonEnabled: false,
        cartButtonVisible: false,
        nextButtonEnabled: false,
        version: null,
    };
};

@State<AppConfigStateModel>({
    name: 'appConfigState',
    defaults: defaultGuiState(),
})

export class AppConfigState implements NgxsOnInit  {
    constructor(private readonly translate: TranslateService,
                private readonly apiService: ApiService,
                private readonly store: Store) {
    }

    @Selector()
    static availableLanguages(state: AppConfigStateModel): string[] {
        return state.availableLanguages;
    }

    @Selector()
    static mainMenuEntries(state: AppConfigStateModel): MainMenuEntriesInterface[] {
        return state.mainMenuEntries;
    }

    @Selector()
    static selectedLanguage(state: AppConfigStateModel): string {
        return state.selectedLanguage;
    }


    @Selector()
    static backendConfig(state: AppConfigStateModel): object {
        return state.backendConfig;
    }

    @Selector()
    static cartButtonEnabled(state: AppConfigStateModel): boolean {
        return state.cartButtonEnabled;
    }

    @Selector()
    static cartButtonVisible(state: AppConfigStateModel): boolean {
        return state.cartButtonVisible;
    }

    @Selector()
    static nextButtonEnabled(state: AppConfigStateModel): boolean {
        return state.nextButtonEnabled;
    }

    ngxsOnInit({ dispatch }: StateContext<AppConfigStateModel>): void | any {
        dispatch(new SetAppVersion());
        dispatch(new SetBackendConfig());
    }

    @Action(SetLanguage)
    setLanguage({ patchState }: StateContext<AppConfigStateModel>, { languageCode }: SetLanguage) {
        patchState({ selectedLanguage: languageCode });
        return this.translate.use(languageCode);
    }

    @Action(SetBackendConfig)
    setBackendConfig({ patchState }: StateContext<AppConfigStateModel>) {
        return this.apiService.getBackendConfig().pipe(tap(data => patchState({
            backendConfig: data
        })));
    }

    @Action(SetCartButtonVisible)
    setCartButtonVisible({ patchState }: StateContext<AppConfigStateModel>, { visible }: SetCartButtonVisible) {
        patchState({ cartButtonVisible: visible });
        return visible;
    }

    @Action(SetCartButtonEnabled)
    setCartButtonEnabled({ patchState }: StateContext<AppConfigStateModel>, { enabled }: SetCartButtonEnabled) {
        patchState({ cartButtonEnabled: enabled });
        return enabled;
    }

    @Action(SetNextButtonEnabled)
    setNextButtonEnabled({ patchState }: StateContext<AppConfigStateModel>, { enabled }: SetNextButtonEnabled) {
        patchState({ nextButtonEnabled: enabled });
        return enabled;
    }

    @Action(SetAppVersion)
    setAppVersion({ patchState }: StateContext<AppConfigStateModel>) {
        return this.apiService.getAppVersion().pipe(tap((data: any) => patchState({
            version: data.appVersion
        })));
    }

}
