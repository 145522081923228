import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { INTENTS } from '../../../../../../../shared/enums/intents.enum';
import { BookInterface } from '../../../../../../../shared/interfaces/book.interface';
import { Select, Store } from '@ngxs/store';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SetSamplePageCount } from '../../state/content.actions';
import { Observable, Subscription } from 'rxjs';
import { ContentState } from '../../state/content.state';
import { RemoveError, ValidateBook } from '../../../shared/state/configuration/configuration.action';
import { BookConfigurationsService } from '../../../../../../../shared/services/book-configurations.service';
import { PageSizeInterface } from '../../../../../../../shared/interfaces/page-size.interface';
import { ColorModeInterface } from '../../interfaces/color-mode.interface';
import { BookErrorsEnum } from '../../../../../../../shared/enums/book-errors.enum';

@Component({
  selector: 'bubu-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit, OnDestroy {
  intent: INTENTS = INTENTS.CONTENT;
  sampleFileBook: Partial<BookInterface>;
  @Select(ContentState.isContentPDFSet) isContentPDFSet$: Observable<boolean>;
  @Select(ContentState.getContentPDFPageCount) contentPDFPageCount$: Observable<number>;
  @Select(ContentState.getSamplePageCount) samplePageCount$: Observable<number>;
  @Select(ContentState.getContentPDFErrors) contentPDFErrors$: Observable<any[]>;
  @Select(ContentState.getContentPDF) contentPDF$: Observable<string>;
  @Select(ConfigurationState.getProjectId) projectId$: Observable<string>;
  @Select(ContentState.getContentPDFSize) contentPdfSize$: Observable<PageSizeInterface>;
  @Select(ConfigurationState.selectedColorMode) colorMode$: Observable<ColorModeInterface>;

  @ViewChild('pdfViewerAutoLoad', { static: true }) pdfViewerAutoLoad;

  hasErrors = false;

  pdfUploaded = false;

  contentPDFSpecs = [
    'CONTENT.SINGLE_PAGE_PDF',
    'CONTENT.CUT',
    'CONTENT.NO_PRINT_MARKS'
  ];

  private samplePageCountSubscription: Subscription;
  private contentPDFSubscription: Subscription;
  private contentPDFPageCountSubscription: Subscription;
  private contentPdfSizeSubscription: Subscription;

  constructor(private readonly store: Store,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    const configuredBook = this.store.selectSnapshot(ConfigurationState.getBook);
    this.sampleFileBook = {
      ...configuredBook
    };
    this.samplePageCountSubscription = this.samplePageCount$.subscribe(pageCount => this.sampleFileBook.pages = pageCount);
    this.contentPDFSubscription = this.contentPDF$.subscribe(pdfData => {
      if (pdfData) {
        this.store.dispatch(new RemoveError(BookErrorsEnum.CONTENT_PDF_MISSING));
      }
    });

    this.contentPDFPageCountSubscription = this.contentPDFPageCount$.subscribe(pageCount => {
      if (pageCount) {
        this.store.dispatch(new RemoveError(BookErrorsEnum.CONTENT_PDF_MISSING));
        this.store.dispatch(new ValidateBook());
      }
    });

    this.contentPdfSizeSubscription = this.contentPdfSize$.subscribe(size => {
      if (size) {
        this.store.dispatch(new ValidateBook());
      }
    });

    if (this.store.selectSnapshot(ContentState.isContentPDFSet)) {
      this.pdfUploaded = true;
      this.store.dispatch(new RemoveError(BookErrorsEnum.CONTENT_PDF_MISSING));
    }
  }

  ngOnDestroy() {
    this.samplePageCountSubscription.unsubscribe();
    this.contentPDFSubscription.unsubscribe();
    this.contentPDFPageCountSubscription.unsubscribe();
    this.contentPdfSizeSubscription.unsubscribe();
  }

  setErrors(errors) {
    this.hasErrors = errors.length > 0;
    this.cdRef.detectChanges();
  }

  onSamplePageCountChange(samplePages) {
    this.store.dispatch(new SetSamplePageCount(samplePages));
  }

  coverModeAvailable(): boolean {
    const montage = this.store.selectSnapshot(ConfigurationState.selectedProductType).id;
    const binding = this.store.selectSnapshot(ConfigurationState.selectedBinding).id;
    return BookConfigurationsService.isCoverModeAvailable(montage, binding);
  }
}
