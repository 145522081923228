
import {PaperSurfaceInterface} from '../interfaces/paper-surface.interface';

export const PAPER_SURFACE_TYPES: PaperSurfaceInterface[] =
  [
    {
      id: 'mattcoated',
      name: 'PAPER.SURFACE.MATTCOATED',
      description: 'PAPER.SURFACE.MATTCOATED_DESCRIPTION',
    },
    {
      id: 'glosscoated',
      name: 'PAPER.SURFACE.GLOSSCOATED',
      description: 'PAPER.SURFACE.GLOSSCOATED_DESCRIPTION',
    },
    {
      id: 'uncoated',
      name: 'PAPER.SURFACE.UNCOATED',
      description: 'PAPER.SURFACE.UNCOATED_DESCRIPTION',
    },
    {
      id: 'premium',
      name: 'PAPER.SURFACE.PREMIUM',
      description: 'PAPER.SURFACE.PREMIUM_DESCRIPTION',
    },
  ];
