import {SurfaceTypeInterface} from '../interfaces/surface-type.interface';

export const SURFACE: SurfaceTypeInterface[] = [
    {
        id: 'none',
        name: 'SURFACE.NONE',
        description: 'SURFACE.NONE_DESCRIPTION',
    },
    {
        id: 'laminate-matt',
        name: 'SURFACE.MATT_LAMINATED',
        description: 'SURFACE.MATT_LAMINATED_DESCRIPTION',
        image: 'laminate-matt.png',
    },
    {
        id: 'laminate-gloss',
        name: 'SURFACE.GLOSS_LAMINATED',
        description: 'SURFACE.GLOSS_LAMINATED_DESCRIPTION',
        image: 'laminate-gloss.png',
    },
    {
        id: 'laminate-softtouch',
        name: 'SURFACE.SOFTTOUCH_LAMINATED',
        description: 'SURFACE.SOFTTOUCH_LAMINATED_DESCRIPTION',
        image: 'laminate-matt.png',
    },
    {
        id: 'weave',
        name: 'SURFACE.WEAVE',
        description: 'SURFACE.WEAVE_DESCRIPTION',
        image: 'weave.png',
    }
];
