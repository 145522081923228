import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetContentPDFPageCount } from '../../../content/state/content.actions';
import { ContentState } from '../../../content/state/content.state';
import { SetPrice } from '../../state/price/price.actions';

@Component({
  selector: 'bubu-page-count',
  templateUrl: './page-count.component.html',
  styleUrls: ['./page-count.component.scss']
})
export class PageCountComponent {

  pageCount: number;
  @Input() isForCalculator = false;

  constructor(private readonly store: Store) {
    this.pageCount = this.store.selectSnapshot(ContentState.getContentPDFPageCount)
      ? this.store.selectSnapshot(ContentState.getContentPDFPageCount) : 1;
  }

  setPageCount(event) {
    this.store.dispatch(new SetContentPDFPageCount(event));
    this.store.dispatch(new SetPrice());

  }
}
