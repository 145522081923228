import { Component, Input } from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppConfigState} from '../../state/gui/app-config.state';
import {SetLanguage} from '../../state/gui/app-config.actions';

@Component({
    selector: 'bubu-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
    @Select(AppConfigState.availableLanguages) availableLanguages$: Observable<string[]>;
    @Input() fullLanguageNames = false;
    selectedLanguage: string;

    constructor(private readonly store: Store) {
        this.selectedLanguage = this.store.selectSnapshot(AppConfigState.selectedLanguage);
    }

    useLanguage(language: string) {
        this.selectedLanguage = language;
        this.store.dispatch(new SetLanguage(language));
    }
}
