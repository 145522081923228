import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsRoutingModule } from './options-routing.module';
import { OptionsContainerComponent } from './containers/options-container/options-container.component';
import { OptionListComponent } from './components/option-list/option-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [OptionsContainerComponent, OptionListComponent],
  imports: [
    CommonModule,
    OptionsRoutingModule,
    SharedModule
  ],
  exports: [
    OptionListComponent
  ]
})
export class OptionsModule {
}
