
import {PaperGroupInterface} from '../interfaces/paper-group.interface';

export const PAPER_GROUP_TYPES: PaperGroupInterface[] =
    [
        {
            id: 'Magno-Satin',
            name: 'PAPER.GROUP.MAGNO-SATIN',
            description: 'PAPER.GROUP.MAGNO-SATIN_DESCRIPTION',
            image: 'paper_magno_satin.jpg',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'Magno-Star',
            name: 'PAPER.GROUP.MAGNO-STAR',
            description: 'PAPER.GROUP.MAGNO-STAR_DESCRIPTION',
            image: '',
            surface: 'glosscoated',
            papers: [],
        },
        {
            id: 'Xper-White',
            name: 'PAPER.GROUP.XPER-WHITE',
            description: 'PAPER.GROUP.XPER-WHITE_DESCRIPTION',
            image: 'paper_x_motion_bright.jpg',
            surface: 'uncoated',
            papers: [],
        },
        {
            id: 'Magno-48x33',
            name: 'PAPER.GROUP.MAGNO-48x33',
            description: 'PAPER.GROUP.MAGNO-48x33_DESCRIPTION',
            image: '',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'Algro',
            name: 'PAPER.GROUP.ALGRO',
            description: 'PAPER.GROUP.ALGRO_DESCRIPTION',
            image: '',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'Splendorgel',
            name: 'PAPER.GROUP.SPLENDORGEL',
            description: 'PAPER.GROUP.SPLENDORGEL_DESCRIPTION',
            image: '',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'CPS',
            name: 'PAPER.GROUP.CPS',
            description: 'PAPER.GROUP.CPS_DESCRIPTION',
            image: '',
            surface: 'premium',
            papers: [],
        },
        {
            id: 'Heaven42-Softmatt',
            name: 'PAPER.GROUP.HEAVEN42-SOFTMATT',
            description: 'PAPER.GROUP.HEAVEN42-SOFTMATT_DESCRIPTION',
            image: 'paper_heaven.jpg',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'Soporset',
            name: 'PAPER.GROUP.SOPORSET',
            description: 'PAPER.GROUP.SOPORSET_DESCRIPTION',
            image: '',
            surface: 'uncoated',
            papers: [],
        },
        {
            id: 'Munken-Kristall',
            name: 'PAPER.GROUP.MUNKEN-KRISTALL',
            description: 'PAPER.GROUP.MUNKEN-KRISTALL_DESCRIPTION',
            image: '',
            surface: 'uncoated',
            papers: [],
        },
        {
            id: 'Munken-Polar',
            name: 'PAPER.GROUP.MUNKEN-POLAR',
            description: 'PAPER.GROUP.MUNKEN-POLAR_DESCRIPTION',
            image: 'paper_munken_polar.jpg',
            surface: 'uncoated',
            papers: [],
        },
        {
            id: 'Profibulk',
            name: 'PAPER.GROUP.PROFIBULK',
            description: 'PAPER.GROUP.PROFIBULK_DESCRIPTION',
            image: 'paper_profibulk.jpg',
            surface: 'mattcoated',
            papers: [],
        },
        {
            id: 'Refutura',
            name: 'PAPER.GROUP.REFUTURA',
            description: 'PAPER.GROUP.REFUTURA_DESCRIPTION',
            image: 'paper_refutura.jpg',
            surface: 'uncoated',
            papers: [],
        },
        {
            id: 'Weave',
            name: 'PAPER.GROUP.WEAVE',
            description: 'PAPER.GROUP.WEAVE_DESCRIPTION',
            image: '',
            surface: 'premium',
            papers: [],
        },
        {
            id: 'Schoeller',
            name: 'PAPER.GROUP.SCHOELLER',
            description: 'PAPER.GROUP.SCHOELLER_DESCRIPTION',
            image: '',
            surface: 'premium',
            papers: [],
        },
    ];
