import {
    Component,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';
import {Store} from '@ngxs/store';
import {ConfigurationState} from '../../../shared/state/configuration/configuration.state';
import {SetProjectTitle} from '../../../shared/state/configuration/configuration.action';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'bubu-project-title',
    templateUrl: './project-title.component.html',
    styleUrls: ['./project-title.component.scss'],
})
export class ProjectTitleComponent implements OnInit, AfterViewInit {
    projectTitle: string;
    editMode = false;
    @ViewChild('titleInput', {static: false}) titleInput: ElementRef;

    @Output()
    titleChanged: EventEmitter<any> = new EventEmitter();

    constructor(private readonly store: Store, private translate: TranslateService) {
        this.projectTitle =
            this.store.selectSnapshot(ConfigurationState.getProjectTitle)
                ? this.store.selectSnapshot(ConfigurationState.getProjectTitle)
                : translate.instant('SUMMARY.PROJECT-TITLE-DEFAULT');
    }

    ngAfterViewInit() {
        this.saveTitle();
    }

    saveTitle() {
        if (this.titleInput.nativeElement.value !== '') {
            this.store.dispatch(new SetProjectTitle(this.titleInput.nativeElement.value));
            this.titleChanged.emit(this.titleInput.nativeElement.value);
        }
        this.setEditmode(false);
    }

    setEditmode(editMode: boolean) {
        this.editMode = editMode;
        if (this.editMode === true) {
            setTimeout(() => {
                this.titleInput.nativeElement.select();
            });
        }
        this.titleInput.nativeElement.value = this.store.selectSnapshot(ConfigurationState.getProjectTitle);
    }

    ngOnInit() {
    }

}
