import {BindingTypeInterface} from '../interfaces/binding-type.interface';
import {BookBinding} from '../../../../../../shared/enums/book-attributes.enum';
export const BINDING_TYPES: BindingTypeInterface[] =
  [
    {
      id: BookBinding.GLUE,
      name: 'BINDING.GLUE',
      description: 'BINDING.GLUE_DESCRIPTION',
      image: 'binding_glue.png',
    },
    {
      id: BookBinding.SEW,
      name: 'BINDING.SEW',
      description: 'BINDING.SEW_DESCRIPTION',
      image: 'binding_sew.png',
    },
    {
      id: BookBinding.FLAT,
      name: 'BINDING.FLAT',
      description: 'BINDING.FLAT_DESCRIPTION',
      image: 'binding_flat.png',
    },
    {
      id: BookBinding.FLAT_OPEN,
      name: 'BINDING.FLAT_OPEN',
      description: 'BINDING.FLAT_OPEN_DESCRIPTION',
      image: 'binding_flat_open.png',
    },
    {
      id: BookBinding.STITCH,
      name: 'BINDING.STITCH',
      description: 'BINDING.STITCH_DESCRIPTION',
      image: 'binding_stitch.png',
    },
    {
      id: BookBinding.WIREO,
      name: 'BINDING.WIREO',
      description: 'BINDING.WIREO_DESCRIPTION',
      image: 'binding_wireo.png',
    },
  ];
