import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {ApiService} from '../../services/api.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {UploadReturnInterface} from '../../../../../../../shared/interfaces/upload-return.interface';
import {ConfigurationState} from '../../state/configuration/configuration.state';
import {
    SelectCoverMode, SetCartId,
    SetProjectId
} from '../../state/configuration/configuration.action';
import {
    IsContentPDFSet,
    SetContentPDF,
    SetContentPDFDate,
    SetContentPDFErrors,
    SetContentPDFOriginalFilename,
    SetContentPDFPageCount,
    SetContentPDFSize
} from '../../../content/state/content.actions';
import {AppConfigState} from '../../state/gui/app-config.state';
import {PdfErrorsEnum} from '../../../../../../../shared/enums/pdf-errors.enum';
import {Store} from '@ngxs/store';
import {
    IsCoverPDFSet,
    SetCoverPDF,
    SetCoverPDFDate,
    SetCoverPDFErrors,
    SetCoverPDFOriginalFilename,
    SetCoverPDFPageCount,
    SetCoverPDFSize
} from '../../../cover/state/cover.actions';
import {CoverModesEnum} from '../../../../../../../shared/enums/cover-modes.enum';
import {BookConfigurationsService} from '../../../../../../../shared/services/book-configurations.service';

@Component({
    selector: 'bubu-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit, OnDestroy {
    @Input()
    intent: INTENTS;

    @Input()
    hasExternalErrors = false;

    @Input()
    fileUploaded = false;

    isUploading = false;
    progress = 0;
    uploadHasError = false;
    uploadSuccessful = false;
    pdfErrors = [];

    private createProjectSubscription: Subscription;
    private uploadPdfSubscription: Subscription;

    constructor(private readonly apiService: ApiService,
                private readonly store: Store) {
    }

    ngOnInit() {
        if (!this.pdfErrors.length && !this.isUploading && !this.hasExternalErrors) {
            this.uploadSuccessful = this.fileUploaded;
        }
    }

    uploadFile(file: File) {
        let coverMode = this.store.selectSnapshot(ConfigurationState.selectedCoverMode).id;
        // if cover mode was one-file and we now upload a separate cover file we change the cover mode tp separate
        if (coverMode === CoverModesEnum.ONE_FILE && this.intent === INTENTS.COVER) {
            this.store.dispatch(new SelectCoverMode(CoverModesEnum.SEPARATE));
            coverMode = CoverModesEnum.SEPARATE;
        }

        const montage = this.store.selectSnapshot(ConfigurationState.selectedProductType).id;
        const binding = this.store.selectSnapshot(ConfigurationState.selectedBinding).id;
        const isCoverModeAvailable = BookConfigurationsService.isCoverModeAvailable(montage, binding);
        if (!isCoverModeAvailable && coverMode === CoverModesEnum.ONE_FILE) {
            // if coverMode is not available for product use SEPARATE as default
            this.store.dispatch(new SelectCoverMode(CoverModesEnum.SEPARATE));
            coverMode = CoverModesEnum.SEPARATE;
        }

        // do checks on frontend (like file size, etc.)
        const frontendChecks = this.pdfFrontendChecks(file);
        if (frontendChecks.length > 0) {
            this.uploadHasError = true;
            this.uploadSuccessful = false;
            if (this.intent === INTENTS.CONTENT) {
                this.store.dispatch(new SetContentPDFErrors(frontendChecks));
            }
            if (this.intent === INTENTS.COVER) {
                this.store.dispatch(new SetCoverPDFErrors(frontendChecks));
            }

        } else {
            const formData = new FormData();
            if (this.intent === INTENTS.CONTENT) {
                formData.append('content', file);
            }
            if (this.intent === INTENTS.COVER) {
                formData.append('cover', file);
            }
            if (!this.store.selectSnapshot(ConfigurationState.getProjectId)) {
                this.apiService.saveUserConfig(this.store.snapshot()).toPromise();
                this.doUpload(formData, file, coverMode);
            } else {
                this.doUpload(formData, file, coverMode);
            }
        }
    }

    private doUpload(formData: FormData, file: File, coverMode: CoverModesEnum) {
        this.uploadPdfSubscription = this.apiService.uploadPdf(formData).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    this.progress = Math.round(event.loaded / event.total * 100);
                    this.pdfErrors = [];
                    this.isUploading = true;
                    this.uploadSuccessful = false;
                    this.uploadHasError = false;
                    break;
                case HttpEventType.Response:
                    console.log('File successfully uploaded!', event.body);
                    this.store.dispatch(new SetProjectId(event.body.projectId));
                    const returnObject: UploadReturnInterface = event.body;
                    this.isUploading = false;
                    if (this.intent === INTENTS.CONTENT) {
                        this.store.dispatch([
                            new SetContentPDFOriginalFilename(file.name),
                            new SetContentPDFDate(new Date(file.lastModified)),
                        ]);

                    }
                    if (this.intent === INTENTS.COVER || (this.intent === INTENTS.CONTENT && coverMode === CoverModesEnum.ONE_FILE)) {
                        this.store.dispatch([
                            new SetCoverPDFOriginalFilename(file.name),
                            new SetCoverPDFDate(new Date(file.lastModified)),
                        ]);
                    }
                    this.progress = 0;
                    if (returnObject[this.intent].errors.length === 0) {
                        this.store.dispatch(new SetContentPDFErrors([]));
                        this.uploadSuccessful = true;
                        if (this.intent === INTENTS.CONTENT) {
                            this.store.dispatch(new SetContentPDFSize({
                                height: returnObject.content.height,
                                width: returnObject.content.width
                            }));
                            this.store.dispatch(new IsContentPDFSet(true));
                            this.store.dispatch(new SetContentPDFPageCount(returnObject.content.pages));
                            this.store.dispatch(new SetContentPDF(returnObject[this.intent].contentPdf));
                        }
                        if (this.intent === INTENTS.COVER || (this.intent === INTENTS.CONTENT && coverMode == CoverModesEnum.ONE_FILE)) {
                            console.log('SetCoverPDFSIZE');
                            this.store.dispatch(new SetCoverPDFSize({
                                height: returnObject.cover.height,
                                width: returnObject.cover.width
                            }));
                            this.store.dispatch(new IsCoverPDFSet(true));
                            this.store.dispatch(new SetCoverPDFPageCount(returnObject.cover.pages));
                            this.store.dispatch(new SetCoverPDF(returnObject[this.intent].contentPdf));
                        }
                    } else {
                        this.uploadSuccessful = false;
                        this.uploadHasError = true;
                        if (this.intent === INTENTS.CONTENT) {
                            if (returnObject[this.intent].errors.length > 0) {
                                this.store.dispatch(new SetContentPDFErrors(returnObject[this.intent].errors));
                            }
                        }
                        if (this.intent === INTENTS.COVER) {
                            if (returnObject[this.intent].errors.length > 0) {
                                this.store.dispatch(new SetCoverPDFErrors(returnObject[this.intent].errors));
                            }
                        }
                    }
            }
        });
    }

    private pdfFrontendChecks(pdf: File): any[] {
        const errors = [];
        const backendConfig = this.store.selectSnapshot(AppConfigState.backendConfig);
        // Dateigröße checken
        if (pdf.size > backendConfig['CONTENT_PDF_MAX_FILE_SIZE'] && this.intent === INTENTS.CONTENT) {
            errors.push(PdfErrorsEnum.CONTENT_FILESIZE_ERROR);
        }
        if (pdf.size > backendConfig['COVER_PDF_MAX_FILE_SIZE'] && this.intent === INTENTS.COVER) {
            errors.push(PdfErrorsEnum.COVER_FILESIZE_ERROR);
        }
        return errors;
    }

    ngOnDestroy() {
        if (this.createProjectSubscription) {
            this.createProjectSubscription.unsubscribe();
        }

        if (this.uploadPdfSubscription) {
            this.uploadPdfSubscription.unsubscribe();
        }
    }
}
