import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SelectSurface } from '../../../shared/state/configuration/configuration.action';
import { SurfaceState } from '../../state/surface.state';
import { PaperSurfaceInterface } from '../../../paper/interfaces/paper-surface.interface';
import { SurfaceTypeInterface } from '../../interfaces/surface-type.interface';

@Component({
  selector: 'bubu-surface-list',
  templateUrl: './surface-list.component.html',
  styleUrls: ['./surface-list.component.scss'],
})
export class SurfaceListComponent implements OnInit, OnDestroy {
  @Select(SurfaceState.surfaceTypes) surfaceTypes$: Observable<any[]>;
  @Select(ConfigurationState.selectedSurfaceType) selectedSurfaceType$: Observable<SurfaceTypeInterface>;

  @Input() showDropDownSurfaceList = false;

  selectedSurface: string;
  surfaceTypeSubscription: Subscription;

  constructor(private readonly store: Store) {
    this.selectedSurface =
      this.store.selectSnapshot(ConfigurationState.selectedSurfaceType) ? this.store.selectSnapshot(ConfigurationState.selectedSurfaceType).id : null;
  }

  onSurfaceTypeChanged(event: string) {
    this.store.dispatch(new SelectSurface(event));
    this.selectedSurface = event;
  }

  ngOnInit() {
      this.surfaceTypeSubscription = this.surfaceTypes$.subscribe(surfaceTypes => {
          let firstSurface = null;
          let foundSelectedSurface = false;
          for (const surface of surfaceTypes) {
              if (!firstSurface) {
                  firstSurface = surface.id;
              }
              if (surface.id === this.selectedSurface) {
                  foundSelectedSurface = true;
              }
          }

          if (!foundSelectedSurface) {
              this.selectedSurface = firstSurface;
              this.onSurfaceTypeChanged(this.selectedSurface);
          }
      });
  }

  ngOnDestroy() {
      this.surfaceTypeSubscription.unsubscribe();
  }

}
