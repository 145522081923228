export enum BookErrorsEnum {
    INVALID_MONTAGE = 'BOOK_ERROR.INVALID_MONTAGE',
    INVALID_BINDING = 'BOOK_ERROR.INVALID_BINDING',
    TOO_MUCH_CONTENT_PAGES = 'BOOK_ERROR.TOO_MUCH_CONTENT_PAGES',
    TOO_LESS_CONTENT_PAGES = 'BOOK_ERROR.TOO_LESS_CONTENT_PAGES',
    INVALID_CONTENT_PAGE_STEPS = 'BOOK_ERROR.INVALID_CONTENT_PAGE_STEPS',
    CONTENT_MAX_THICKNESS_EXCEEDED = 'BOOK_ERROR.CONTENT_MAX_THICKNESS_EXCEEDED',
    INVALID_SURFACE = 'BOOK_ERROR.INVALID_SURFACE',
    INVALID_CONTENT_PAPER = 'BOOK_ERROR.INVALID_CONTENT_PAPER',
    INVALID_FORMAT = 'BOOK_ERROR.INVALID_FORMAT',
    INVALID_OPTION = 'BOOK_ERROR.INVALID_OPTION',
    MISSING_OPTION = 'BOOK_ERROR.MISSING_OPTION',
    INVALID_OPTION_VALUE = 'BOOK_ERROR.INVALID_OPTION_VALUE',
    INVALID_COLOR = 'BOOK_ERROR.INVALID_COLOR',
    INVALID_TAX_RATE = 'BOOK_ERROR.INVALID_TAX_RATE',
    INVALID_COVER_PAGES = 'BOOK_ERROR.INVALID_COVER_PAGES',
    CONTENT_PDF_MISSING = 'BOOK_ERROR.CONTENT_PDF_MISSING',
    CONTENT_PDF_SIZE_WRONG = 'BOOK_ERROR.CONTENT_PDF_SIZE_WRONG',
    COVER_PDF_MISSING = 'BOOK_ERROR.COVER_PDF_MISSING',
    COVER_PDF_SIZE_WRONG = 'BOOK_ERROR.COVER_PDF_SIZE_WRONG',
}
