export interface MainMenuEntriesInterface {
  link: string;
  name: string;
  additionalName?: string;
}

export const MAIN_MENU_ENTRIES: MainMenuEntriesInterface[] =

  [
    {
      link: 'product',
      name: 'TYPE.PRODUCT_TYPE',
      additionalName: 'TYPE.ADDITIONAL_NAME'
    },
    {
      link: 'binding',
      name: 'BINDING.BINDING'
    },
    {
      link: 'paper',
      name: 'PAPER.PAPER'
    },
    {
      link: 'content',
      name: 'CONTENT.CONTENT'
    },
    {
      link: 'cover',
      name: 'COVER.COVER'
    },
    {
      link: 'surface',
      name: 'SURFACE.SURFACE'
    },
    {
      link: 'options',
      name: 'OPTION.OPTIONS'
    },
    {
      link: 'summary',
      name: 'SUMMARY.SUMMARY'
    },
  ]

