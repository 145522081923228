import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bubu-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input()
  id!: string;
  @Input()
  name!: string;
  @Input()
  value?: any;
  @Input()
  label?: string;
  @Input()
  imageUrl?: string;
  @Input()
  selected?: boolean;
  @Input()
  disabled?: boolean;
  @Output()
  radioChanged: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

  }

  onRadioClicked(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.radioChanged.emit(this.value);
    }
  }
}
