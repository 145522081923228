import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { PaperFormatInterface } from '../../interfaces/paper-format.interface';
import { ProductState } from '../../state/product.state';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SelectPaperFormat, ValidateBook } from '../../../shared/state/configuration/configuration.action';

@Component({
  selector: 'bubu-paper-format',
  templateUrl: './paper-format.component.html',
  styleUrls: ['./paper-format.component.scss'],
})
export class PaperFormatComponent implements OnInit, OnDestroy {
  @Select(ProductState.filteredProducts) filteredPaperFormats$: Observable<PaperFormatInterface[]>;
  @Select(ConfigurationState.selectedPaperFormat) selectedPaperFormat$: Observable<PaperFormatInterface>;
  @Input() showHint = true;
  selectedPaperFormat: string;
  selectedPaperFormatSubscription: Subscription;
  filteredProductsSubscription: Subscription;

  constructor(private readonly store: Store) {
    this.selectedPaperFormatSubscription = this.selectedPaperFormat$.subscribe(selectedPaperFormat => {
      if (selectedPaperFormat) {
        this.selectedPaperFormat = selectedPaperFormat.id;
      }
    });

    this.filteredProductsSubscription = this.filteredPaperFormats$.subscribe(formats => {
      let formatFound = false;
      if (formats) {
        for (const format of formats) {
          if (format.id === this.selectedPaperFormat) {
            formatFound = true;
            break;
          }
        }

        if (!formatFound) {
          // set to first format in format list if selected format is not available
          if (formats[0]) {
            this.selectedPaperFormat = formats[0].id;
            this.store.dispatch(new SelectPaperFormat(this.selectedPaperFormat));
          }
        }
      }
    });
  }

  onPaperFormatChanged(event: any) {
    this.selectedPaperFormat = event.value;
    this.store.dispatch(new SelectPaperFormat(event.value));
    this.store.dispatch(new ValidateBook());
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.selectedPaperFormatSubscription.unsubscribe();
    this.filteredProductsSubscription.unsubscribe();
  }

}
