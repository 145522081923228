import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppConfigState } from '../../../shared/state/gui/app-config.state';
import { MainMenuEntriesInterface } from '../../../shared/state/gui/config/main-menu-entries';
import { RouteServiceService } from '../../../shared/services/route-service.service';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SetIsCalculator } from '../../../shared/state/configuration/configuration.action';

@Component({
  selector: 'bubu-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent implements OnInit {
  @Select(AppConfigState.mainMenuEntries) mainMenuEntries$: Observable<MainMenuEntriesInterface[]>;
  isCalculator = false;
  currentUrl = '';

  constructor(private readonly route: ActivatedRoute,
              public routeService: RouteServiceService,
              public router: Router,
              private readonly store: Store) {}


  ngOnInit(): void {
    // if we are on the calculator route we get a data attribute
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)).subscribe((data: ActivatedRoute) => {
      this.currentUrl = ((data.url) as null as string);
      this.isCalculator = ((data.url) as null as string) === environment.calculatorUrl;
      this.store.dispatch(new SetIsCalculator(this.isCalculator));
    });
  }

  showMenu() {
    if (this.isCalculator) {
      return false;
    } else {
      return !this.route.snapshot.children.some(route => route.url[0].path.includes('summary'));
    }
  }

  showMenuEntry(mainMenuEntry) {
    return !mainMenuEntry.link.includes('summary');
  }
}
