import {Component, EventEmitter, Input, Output, OnDestroy} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {saveAs} from 'file-saver';
import {Subscription} from 'rxjs';
import {Store} from '@ngxs/store';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {BookInterface} from '../../../../../../../shared/interfaces/book.interface';
import {ConfigurationState} from '../../state/configuration/configuration.state';
import {PreviewOptionsReturnInterface} from '../../../../../../../shared/interfaces/preview-options-return.interface';


@Component({
    selector: 'bubu-sample-files',
    templateUrl: './sample-files.component.html',
    styleUrls: ['./sample-files.component.scss']
})

export class SampleFilesComponent implements OnDestroy {
    @Input()
    intent!: INTENTS;
    @Input()
    book!: BookInterface;
    @Input()
    samplePageCount = 16;
    @Input()
    disableFlyout = false;
    @Output()
    samplePageCountChange: EventEmitter<number> = new EventEmitter<number>();

    flyOutVisible = false;

    private sampleFileSubscription: Subscription;
    private previewOptionsSubscription: Subscription;

    private pagesMin: number;
    private pagesMax: number;
    private pagesStep: number;

    constructor(private readonly apiService: ApiService, private readonly store: Store) {
        const book = this.store.selectSnapshot(ConfigurationState.getBook);
        this.previewOptionsSubscription = this.apiService.previewOptions(book).subscribe((previewConfig: PreviewOptionsReturnInterface) => {
            this.pagesMin = previewConfig.content.pages.min;
            this.pagesMax = previewConfig.content.pages.max;
            this.pagesStep = previewConfig.content.pages.step;
            if (this.intent === INTENTS.CONTENT) {
                this.book.pages = this.pagesMin;
            } else {
                this.book.pages = book.pages;
            }
        });
    }

    downloadSampleFile() {
        this.sampleFileSubscription = this.apiService.getSampleFile({
            book: this.book,
            intent: this.intent
        }).subscribe(response => {
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace('"', '');
            this.flyOutVisible = false;
            saveAs(response.body, filename);
        });
    }

    onPageCountChange(event) {
        this.samplePageCountChange.emit(event);
    }

    ngOnDestroy() {
        if (this.sampleFileSubscription) {
            this.sampleFileSubscription.unsubscribe();
        }

        if (this.previewOptionsSubscription) {
            this.previewOptionsSubscription.unsubscribe();
        }
    }

}
