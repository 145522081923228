import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BindingTypeInterface } from '../../interfaces/binding-type.interface';
import { BindingState } from '../../state/binding.state';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SelectBindingType, ValidateBook } from '../../../shared/state/configuration/configuration.action';

@Component({
  selector: 'bubu-binding-type-list',
  templateUrl: './binding-type-list.component.html',
  styleUrls: ['./binding-type-list.component.scss'],
})
export class BindingTypeListComponent implements OnInit, OnDestroy {
  @Select(BindingState.filteredBindingTypes) bindingTypes$: Observable<BindingTypeInterface[]>;
  @Select(ConfigurationState.selectedBinding) selectedBinding$: Observable<BindingTypeInterface>;

  @Input() showDropDownBinding = false;
  selectedBinding: string;
  bindingTypeSubscription: Subscription;

  constructor(private readonly store: Store) {
    this.selectedBinding =
      this.store.selectSnapshot(ConfigurationState.selectedBinding) ? this.store.selectSnapshot(ConfigurationState.selectedBinding).id : null;
  }

  onBindingTypeChanged(event: string) {
    this.selectedBinding = event;
    this.store.dispatch(new SelectBindingType(event));
    this.store.dispatch(new ValidateBook());
  }

  ngOnInit() {
    this.bindingTypeSubscription = this.bindingTypes$.subscribe(bindingTypes => {
      let firstBinding = null;
      let foundSelectedBinding = false;
      for (const binding of bindingTypes) {
        if (!firstBinding) {
          firstBinding = binding.id;
        }
        if (binding.id === this.selectedBinding) {
          foundSelectedBinding = true;
        }
      }

      if (!foundSelectedBinding) {
        this.selectedBinding = firstBinding;
        this.onBindingTypeChanged(this.selectedBinding);
      }
    });
  }

  ngOnDestroy() {
    this.bindingTypeSubscription.unsubscribe();
  }

}
