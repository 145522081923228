import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {PriceStateModel} from './price-state.model';
import {SetPrice} from './price.actions';
import {Price} from '../../../../../../../shared/classes/price';
import {ConfigurationState} from '../configuration/configuration.state';
import {ApiService} from '../../services/api.service';

const defaultPriceState = (): PriceStateModel => {
    return <PriceStateModel>{
        price: null,
    };
};

@State<PriceStateModel>({
    name: 'priceState',
    defaults: defaultPriceState(),
})

export class PriceState {

    constructor(private apiService: ApiService, private readonly store: Store) {
    }

    @Selector()
    static getPrice(state: PriceStateModel): Price {
        return state.price;
    }

    @Action(SetPrice)
    setPrice({patchState}: StateContext<PriceStateModel>) {
        const tax = this.store.selectSnapshot(ConfigurationState.selectedTax);
        return this.apiService.getPrice({
                ...this.store.selectSnapshot(ConfigurationState.getBook),
                tax,
            },
            this.store.selectSnapshot(ConfigurationState.getQuantity)
        ).pipe(
            tap((data: Price) => patchState({
                price: this.getData(data),
            })),
        );
    }

    getData(data) {
        return data;
    }
}
