import { ColorModeInterface } from '../interfaces/color-mode.interface';
import { BookContentColor } from '../../../../../../shared/enums/book-content-color.enum';

export const COLOR_MODES: ColorModeInterface[] =
  [
    {
      id: BookContentColor.SW,
      name: 'CONTENT.COLOR-MODE-BW',
      description: 'CONTENT.COLOR-MODE-BW-DESCRIPTION',
    },
    {
      id: BookContentColor.CMYK,
      name: 'CONTENT.COLOR-MODE-CMYK',
      description: 'CONTENT.COLOR-MODE-CMYK-DESCRIPTION',
    }
  ];
