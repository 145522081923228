import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'bubu-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent implements OnInit {


  infoFromResolver = '';

  constructor(private readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    this.infoFromResolver = this.route.snapshot.data.additionalHints ? this.route.snapshot.data.additionalHints : '';
  }

}
