import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InnerContainerComponent} from '../main/containers/inner-container/inner-container.component';
import {PriceComponent} from '../shared/components/price/price.component';
import {SelectionsComponent} from '../shared/components/selections/selections.component';
import {BindingContainerComponent} from './containers/binding-container/binding-container.component';


const bindingRoutes: Routes = [
  {
    path: 'binding',
    component: InnerContainerComponent,
    children: [
      {
        path: '',
        component: BindingContainerComponent,
      },
      {
        path: '',
        outlet: 'additional-information',
        component: SelectionsComponent,
      },
      {
        path: '',
        outlet: 'additional-price',
        component: PriceComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(bindingRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class BindingRoutingModule {
}
