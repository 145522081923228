import { Component, OnInit } from '@angular/core';
import {Store} from '@ngxs/store';
import {SetPaper} from '../../state/paper.actions';

@Component({
  selector: 'bubu-paper-container',
  templateUrl: './paper-container.component.html',
  styleUrls: ['./paper-container.component.scss']
})
export class PaperContainerComponent implements OnInit {

  constructor(private readonly store: Store) {
    store.dispatch(new SetPaper());
  }


  ngOnInit() {
  }

}
