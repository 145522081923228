import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InnerContainerComponent} from '../main/containers/inner-container/inner-container.component';
import {PriceComponent} from '../shared/components/price/price.component';
import {SelectionsComponent} from '../shared/components/selections/selections.component';
import {SummaryContainerComponent} from './containers/summary-container/summary-container.component';
import {QuantityComponent} from '../shared/components/quantity/quantity.component';


const summaryRoutes: Routes = [
  {
    path: 'summary',
    component: InnerContainerComponent,
    children: [
      {
        path: '',
        component: SummaryContainerComponent,
      },
      {
        path: '',
        outlet: 'additional-information',
        component: SelectionsComponent,
      },
        {
            path: '',
            outlet: 'additional-quantity',
            component: QuantityComponent
        },
      {
        path: '',
        outlet: 'additional-price',
        component: PriceComponent,
          data: {
              detailMode: true
          }
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(summaryRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class SummaryRoutingModule {
}
