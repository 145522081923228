import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {BindingRoutingModule} from './binding-routing.module';
import {BindingTypeListComponent} from './components/binding-type-list/binding-type-list.component';
import {BindingContainerComponent} from './containers/binding-container/binding-container.component';


@NgModule({
  declarations: [BindingContainerComponent, BindingTypeListComponent],
  imports: [
    BindingRoutingModule,
    SharedModule,
    CommonModule,
  ],
  exports: [
    BindingTypeListComponent
  ]
})
export class BindingModule {
}
