import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {SampleFileInterface} from '../../../../../../shared/interfaces/sample-file.interface';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {BookInterface} from '../../../../../../shared/interfaces/book.interface';
import {ConfigurationStateModel} from '../../../../../../shared/interfaces/configuration-state.model';
import {CoverModesEnum} from '../../../../../../shared/enums/cover-modes.enum';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    backend: string = environment.backendUrl;
    private httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };

    constructor(private http: HttpClient) {
    }

    public getBackendConfig(): Observable<object> {
        return this.http.get(this.backend + '/config');
    }

    public getPaperFormatsAndAvailabilities(): Observable<any> {
        return this.http.get(this.backend + '/book/paperformats');
    }

    public getPapers(productType: string, binding: string, pageCount: number) {
        return this.http.get(`${this.backend}/book/available-content-papers/${productType}/${binding}/${pageCount}`);
    }

    public getAppVersion() {
        return this.http.get(`/version.json`);
    }

    public getSampleFile(sampleFileRequest: Partial<SampleFileInterface>): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<SampleFileInterface>(this.backend + '/sample-file/pdf', sampleFileRequest, {
            headers,
            // @ts-ignore
            responseType: 'blob',
            // @ts-ignore
            observe: 'response'
        });
    }

    public getOptions(configuredBook: Partial<BookInterface>) {
        return this.http.post(this.backend + '/book/available-options', configuredBook);
    }

    public getPrice(configuredBook: Partial<BookInterface>, quantity: number) {
        if (this.checkIfBookComplete(configuredBook)) {
            return this.http.post(`${this.backend}/price/book/${quantity}`, configuredBook);
        }
        // we return a null observable to no confuse the action
        return of(null);
    }

    public validate(configuredBook: Partial<BookInterface>) {
        return this.http.post(this.backend + '/book/validate', configuredBook);
    }

    public previewOptions(configuredBook: Partial<BookInterface>) {
        return this.http.post(this.backend + '/book/preview-options', configuredBook);
    }

    uploadPdf(form: FormData) {
        return this.http.post(this.backend + '/pdf', form,
            {
                responseType: 'json', reportProgress: true,
                observe: 'events'
            }).pipe(
            catchError(this.errorMgmt)
        );
    }

    changeCoverMode(projectId: string, coverMode: CoverModesEnum): any {
        return this.http.post(this.backend + `/pdf/change-cover-mode/${projectId}/${coverMode}`, {});
    }

    getCoverModeSeparatedFile(projectId: string) {
        return this.http.get(this.backend + `/pdf/cover-mode-separate-file/${projectId}`);
    }

    saveUserConfig(config: any) {
        return this.http.post(this.backend + '/config', config)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    getUserConfig(projectId: string): Observable<ConfigurationStateModel> {
        return this.http.get<ConfigurationStateModel>(`${this.backend}/config/userconfig/${projectId}`)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    createProjectAndCart(book: Partial<BookInterface>) {
        return this.http.post(this.backend + '/cart-api/project-cart', book).pipe(
            catchError(this.errorMgmt)
        );
    }

    finishCart(cartId: string, book: Partial<BookInterface>) {
        return this.http.post(`${this.backend}/cart-api/finish-project/${cartId}`, book)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

    checkIfBookComplete(book: Partial<BookInterface>): boolean {
        const needProperties = ['montage', 'color', 'binding', 'width', 'height', 'pages', 'paperCode', 'options', 'tax'];
        return needProperties.every(property => book.hasOwnProperty(property));
    }
}
