import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaperGroupInterface } from '../../interfaces/paper-group.interface';
import { SelectPaper, SelectPaperGroup, ValidateBook } from '../../../shared/state/configuration/configuration.action';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { PaperSurfaceInterface } from '../../interfaces/paper-surface.interface';

@Component({
  selector: 'bubu-paper-select',
  templateUrl: './paper-select.component.html',
  styleUrls: ['./paper-select.component.scss'],
})
export class PaperSelectComponent {
  @Select(ConfigurationState.filteredPaperGroups) paperGroups$: Observable<PaperGroupInterface[]>;
  @Select(ConfigurationState.selectedPaperGroup) selectedPaperGroup$: Observable<PaperGroupInterface>;
  @Select(ConfigurationState.selectedSurface) selectedSurface$: Observable<PaperSurfaceInterface>;

  @Input() showDropDownProduct = false;
  @Input()
  componentWidth = 0;
  @Input()
  offsetLeft = 0;

  selectedPaper: string;
  selectedPaperGroup: string;

  constructor(private readonly store: Store) {
    // if paper surface is change we reset the paper group selection
    this.selectedSurface$.subscribe(() =>
      this.selectedPaperGroup = this.store.selectSnapshot(ConfigurationState.filteredPaperGroups)[0] ? this.store.selectSnapshot(ConfigurationState.filteredPaperGroups)[0].id : null);


    this.selectedPaper =
      this.store.selectSnapshot(ConfigurationState.selectedPaper) ? this.store.selectSnapshot(ConfigurationState.selectedPaper).id : null;

    this.selectedPaperGroup$.subscribe(paperGroup =>
      this.selectedPaperGroup = paperGroup ? paperGroup.id : null);
    this.store.dispatch(new ValidateBook());
  }


  onPaperGroupChanged(event: string) {
    if (event !== this.selectedPaperGroup) {
      this.selectedPaperGroup = event;
      this.store.dispatch(new SelectPaperGroup(event));
     // if the paper group changes we reset the paper selection to the first entry
      this.selectedPaper = this.store.selectSnapshot(ConfigurationState.selectedPaperGroup).papers[0] ? this.store.selectSnapshot(ConfigurationState.selectedPaperGroup).papers[0].id : null;
    }
  }

  onPaperChanged(event: string, paperGroupId: string) {
    this.onPaperGroupChanged(paperGroupId);
    this.store.dispatch(new SelectPaper(event));
    this.store.dispatch(new ValidateBook());
  }

}
