import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppConfigState } from '../state/gui/app-config.state';

export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloneReq = req.clone({
        setHeaders: {
          'x-user-language': this.store.selectSnapshot(AppConfigState.selectedLanguage)
        }
      })
    ;
    return next.handle(cloneReq);
  }
}
