import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SurfaceRoutingModule } from './surface-routing.module';
import { SurfaceContainerComponent } from './containers/surface-container/surface-container.component';
import { SurfaceListComponent } from './components/surface-list/surface-list.component';


@NgModule({
  declarations: [SurfaceContainerComponent, SurfaceListComponent],
  imports: [
    SurfaceRoutingModule,
    CommonModule,
    SharedModule
  ],
  exports: [SurfaceListComponent]
})
export class SurfaceModule {
}
