import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {SetBindingTypes} from '../../state/binding.actions';

@Component({
  selector: 'bubu-binding-container',
  templateUrl: './binding-container.component.html',
  styleUrls: ['./binding-container.component.scss'],
})
export class BindingContainerComponent implements OnInit {

  constructor(private readonly store: Store) {
    store.dispatch(new SetBindingTypes());
  }

  ngOnInit() {
  }

}
