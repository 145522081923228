import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {ApiService} from '../../shared/services/api.service';
import {OptionStateModel} from './option-state.model';
import {OPTION_TYPES} from '../configs/option-types';
import {SetOptions} from './option.actions';
import {ConfigurationState} from '../../shared/state/configuration/configuration.state';
import {OptionTypeInterface} from '../interfaces/option-type.interface';
import {BookConfigurationsService} from '../../../../../../shared/services/book-configurations.service';

const defaultOptionState = (): OptionStateModel => {
    return <OptionStateModel>{
        options: OPTION_TYPES,
    };
};

@State<OptionStateModel>({
    name: 'optionState',
    defaults: defaultOptionState(),
})

export class OptionState {

    constructor(private apiService: ApiService, private readonly store: Store) {
    }

    @Selector()
    static options(state: OptionStateModel): OptionTypeInterface[] {
        return state.options;
    }


    @Action(SetOptions)
    setOptions({patchState}: StateContext<OptionStateModel>) {
        const configuredBook = this.store.selectSnapshot(ConfigurationState.getBook);
        return this.apiService.getOptions(configuredBook).pipe(
            // @ts-ignore
            tap(data => patchState({
                options: this.buildOptionsArray(data),
            })),
        );
    }

    private buildOptionsArray(optionsRaw: any): OptionTypeInterface[] {
        const returnArray: OptionTypeInterface[] = [];

        for (const option of OPTION_TYPES) {

            let available = false;
            let mandatory = false;
            let selected = '';

            option.values = [];
            BookConfigurationsService.getAllOptionDefinitions()[option.id].values.map(opt => {
                option.values.push({
                    id: opt,
                    name: 'OPTION.VALUE.' + opt.toUpperCase()
                });
            });

            optionsRaw.map(opt => {
                if (opt.name === option.id.toLowerCase()) {
                    available = true;
                    mandatory = opt.mandatory;
                    selected = opt.selected;
                }

            });

            option.selected = selected;
            option.available = available;
            option.mandatory = mandatory;
            returnArray.push(option);

        }

        return returnArray;
    }

}
