export enum BookMontage {
    HANG = 'hang',
    FIXING = 'fixing',
    STITCH = 'stitch',
    WIREO = 'wireo',
}

export enum BookBinding {
    FLAT = 'flat',
    FLAT_OPEN = 'flat_open',
    GLUE = 'glue',
    SEW = 'sew',
    STITCH = 'stitch',
    WIREO = 'wireo',
}