import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { DeselectOption, SelectOption, ValidateBook } from '../../../shared/state/configuration/configuration.action';
import { OptionTypeInterface } from '../../interfaces/option-type.interface';
import { OptionState } from '../../state/option.state';
import { OptionInterface } from '../../../../../../../shared/interfaces/option.interface';

@Component({
  selector: 'bubu-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent implements OnInit, OnDestroy {
  @Select(OptionState.options) options: Observable<OptionTypeInterface[]>;
  @Select(ConfigurationState.selectedOptions) selectedOptions$: Observable<OptionInterface[]>;
  @Input() isPriceCalculator = false;

  initialized: boolean;
  selectedOptions: any;

  optionsSubscription: Subscription;

  constructor(private readonly store: Store) {
    // this.selectedOptions =
    //   this.store.selectSnapshot(ConfigurationState.selectedOptions) ? this.store.selectSnapshot(ConfigurationState.selectedOptions) : null;
    this.selectedOptions$.subscribe(selectedOptions => {
      this.selectedOptions = selectedOptions ? selectedOptions : null;
    });
    this.initialized = false;

    this.optionsSubscription = this.options.subscribe(options => {
      // set all mandatory and defaults initially in configurations state
      for (const opt of options) {
        if (opt.available && opt.selected) {
          let setDefault = true;

          for (const selectedOption of this.selectedOptions) {
            if (selectedOption.selected !== opt.selected) {
              setDefault = false;
            }
          }

          if (!setDefault && opt.mandatory) {
            setDefault = true;
          }

          if (setDefault) {
            this.store.dispatch(new SelectOption(opt.id, opt.selected));
          }
        } else if (!opt.available) {
          this.store.dispatch(new DeselectOption(opt.id));
          this.selectedOptions = this.store.selectSnapshot(ConfigurationState.selectedOptions);
        }
      }
      this.initialized = true;
      this.store.dispatch(new ValidateBook());
    });
  }

  isOptionSelected(optionId: string, optionValueId: string = '') {
    let selected = false;
    for (const opt of this.selectedOptions) {
      if (opt.name === optionId && (!optionValueId || opt.selected === optionValueId)) {
        selected = true;
      }
    }
    return selected;
  }

  isSelected(optionId: any) {
    const selectedOption = this.selectedOptions.find(option => option.name === optionId);
    if (selectedOption) {
      return selectedOption.selected;
    }
    return '';
  }

  onOptionSelect(event: string) {
    this.store.dispatch(new SelectOption(event, ''));
    this.selectedOptions = this.store.selectSnapshot(ConfigurationState.selectedOptions);
  }

  onOptionDeselect(event: string) {
    this.onOptionValueChanged('', event);
    this.store.dispatch(new DeselectOption(event));
    this.selectedOptions = this.store.selectSnapshot(ConfigurationState.selectedOptions);
  }

  onOptionValueChanged(event: string, optionId: string) {
    this.onOptionSelect(optionId);
    this.store.dispatch(new SelectOption(optionId, event));
    this.selectedOptions = this.store.selectSnapshot(ConfigurationState.selectedOptions);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
    }
  }

}
