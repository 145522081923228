import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    text: string;
    title: string;
    textYes: string;
    textNo: string;
    type: PopUpDialogType;
}

export enum PopUpDialogType {
    CONFIRM = 'confirm',
    ALERT = 'alert'
}

@Component({
    selector: 'bubu-pop-up-dialog',
    templateUrl: './pop-up-dialog.component.html',
    styleUrls: ['./pop-up-dialog.component.scss']
})
export class PopUpDialogComponent {

    constructor(public dialogRef: MatDialogRef<PopUpDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }
}
