import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiService } from '../../shared/services/api.service';
import { BindingStateModel } from './binding-state.model';
import { BINDING_TYPES } from '../configs/binding-types';
import { BindingTypeInterface } from '../interfaces/binding-type.interface';
import { SetBindingTypes } from './binding.actions';
import { ConfigurationState } from '../../shared/state/configuration/configuration.state';
import { BookConfigurationsService } from '../../../../../../shared/services/book-configurations.service';
import { ProductTypeInterface } from '../../product/interfaces/product-type.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaperFormatInterface } from '../../product/interfaces/paper-format.interface';

const defaultBindingState = (): BindingStateModel => {
  return <BindingStateModel>{
    bindingTypes: BINDING_TYPES,
  };
};

@State<BindingStateModel>({
  name: 'bindingState',
  defaults: defaultBindingState(),
})
@Injectable()
export class BindingState {
  @Select(ConfigurationState.selectedProductType) productType$: Observable<ProductTypeInterface>;
  @Select(ConfigurationState.selectedPaperFormat) format$: Observable<PaperFormatInterface>;


  constructor(private apiService: ApiService, private readonly store: Store) {
  }

  @Selector()
  static filteredBindingTypes(state: BindingStateModel): BindingTypeInterface[] {
    return state.bindingTypes;
  }


  @Action(SetBindingTypes)
  setBindingTypes(context: StateContext<BindingStateModel>) {
    this.productType$.subscribe(productType => {
      if (productType) {
        const bindingTypesRaw = BookConfigurationsService.getAvailableBindings(productType.id);
        return context.patchState({ bindingTypes: this.buildBindingTypeArray(bindingTypesRaw, productType) });
      } else return {};
    });
  }

  private buildBindingTypeArray(bindingTypesRaw: any, productType: ProductTypeInterface): BindingTypeInterface[] {

    const returnArray: BindingTypeInterface[] = [];
    for (const i in bindingTypesRaw) {
      const binding = bindingTypesRaw[i];
      returnArray.push({
        id: binding,
        name: 'BINDING.' + binding.toUpperCase(),
        description: 'BINDING.' + binding.toUpperCase() + '_DESCRIPTION',
        image: `binding_${binding}.png`,
      });
    }


    // remove bindings if they are not available with the selected format
    this.format$.subscribe(format => {
      for (let i = 0; i < returnArray.length; i++) {
        const availableFormats = BookConfigurationsService.getAvailableFormats(productType.id, returnArray[i].id);
        const foundFormat = availableFormats.find(availableFormat => availableFormat.name === format.id);
        if (!foundFormat) {
          returnArray.splice(i, 1);
          i--;
        }
      }
    });


    return returnArray;
  }

}
