import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bubu-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input()
  id!: string;
  @Input()
  name!: string;
  @Input()
  value?: any;
  @Input()
  label?: string;
  @Input()
  imageUrl?: string;
  @Input()
  checked?: boolean;
  @Input()
  disabled?: boolean;
  @Output()
  checkboxChecked: EventEmitter<any> = new EventEmitter();
  @Output()
  checkboxUnchecked: EventEmitter<any> = new EventEmitter();


  constructor() {
  }

  ngOnInit() {

  }

  onCheckboxClicked(event: Event) {

    if ((event.target as HTMLInputElement).checked) {
      this.checkboxChecked.emit(this.value);
    } else {
      this.checkboxUnchecked.emit(this.value);
    }
  }
}
