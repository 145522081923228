import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {PopUpDialogComponent} from '../pop-up-dialog/pop-up-dialog.component';
import {StateClear} from 'ngxs-reset-plugin';

@Component({
    selector: 'bubu-reset-configurator',
    templateUrl: './reset-configurator.component.html',
    styleUrls: ['./reset-configurator.component.scss']
})
export class ResetConfiguratorComponent implements OnInit {

    constructor(private readonly store: Store,
                private readonly router: Router,
                public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    resetConfiguration() {

        const dialogRef = this.dialog.open(PopUpDialogComponent, {
            width: '650px',
            data: {text: 'COMMON.RESET_CONFIGURATION_TEXT', title: 'COMMON.RESET_CONFIGURATION'}
        });

        dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
            if (confirmed === true) {
                // FIXME maybe use a separate storage for cartId or other Informations
                // FIXME which should be contained for the user session
                const cartId = localStorage.getItem('cartId') || '0';
                localStorage.clear();
                this.store.dispatch([
                        new StateClear()
                    ]
                );

                localStorage.setItem('cartId', cartId);
                await this.router.navigate(['/']);
                location.reload();
            }
        });
    }
}
