import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PaperContainerComponent} from './containers/paper-container/paper-container.component';
import {PaperRoutingModule} from './paper-routing.module';
import {PaperListComponent} from './components/paper-list/paper-list.component';
import {SharedModule} from '../shared/shared.module';
import {PaperSelectComponent} from './components/paper-select/paper-select.component';


@NgModule({
  declarations: [PaperContainerComponent, PaperListComponent, PaperSelectComponent],
  imports: [
    CommonModule,
    PaperRoutingModule,
    SharedModule,
  ],
  exports: [PaperListComponent, PaperSelectComponent]
})
export class PaperModule {
}
