import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bubu-pdf-error',
  templateUrl: './pdf-error.component.html',
  styleUrls: ['./pdf-error.component.scss']
})
export class PdfErrorComponent implements OnInit {
  @Input()
  pdfErrors = [];

  constructor() { }

  ngOnInit() {
  }

}
