import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationState } from '../../state/configuration/configuration.state';
import { SetPrice } from '../../state/price/price.actions';
import { PriceState } from '../../state/price/price.state';
import { Price } from '../../../../../../../shared/classes/price';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bubu-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnDestroy {
  @Input()
  detailMode = false;
  @Input()
  isPriceCalculator = false;
  price: Price;
  @Select(PriceState.getPrice) price$: Observable<Price>;
  @Select(ConfigurationState) configurationState$: Observable<any>;
  selectedTax: number;

  private priceSubscription: Subscription;
  private configurationStateSubscription: Subscription;

  constructor(private readonly store: Store, private readonly route: ActivatedRoute) {

    this.selectedTax = this.store.selectSnapshot(ConfigurationState.selectedTax);

    this.configurationStateSubscription = this.configurationState$.subscribe(() => {
      this.store.dispatch(new SetPrice());
    });
    this.priceSubscription = this.price$.subscribe(price => {
      if (price) {
        this.price = price;
        this.price.net = this.price.net - (-this.price.discountValue);
      }
    });
  }

  taxChange(tax) {
    this.selectedTax = tax;
  }

  ngOnDestroy() {
    this.configurationStateSubscription.unsubscribe();
    this.priceSubscription.unsubscribe();
  }

  ngOnInit() {
    this.detailMode = this.route.snapshot.data.detailMode;
  }

}
