import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaperFormatInterface } from '../../../product/interfaces/paper-format.interface';
import { ProductTypeInterface } from '../../../product/interfaces/product-type.interface';
import { BindingTypeInterface } from '../../../binding/interfaces/binding-type.interface';
import { ConfigurationState } from '../../state/configuration/configuration.state';
import { PaperInterface } from '../../../paper/interfaces/paper.interface';
import { PaperSurfaceInterface } from '../../../paper/interfaces/paper-surface.interface';
import { SurfaceTypeInterface } from '../../../surface/interfaces/surface-type.interface';
import { OptionInterface } from '../../../../../../../shared/interfaces/option.interface';
import { PaperGroupInterface } from '../../../paper/interfaces/paper-group.interface';
import { ColorModeInterface } from '../../../content/interfaces/color-mode.interface';
import { ContentState } from '../../../content/state/content.state';

@Component({
  selector: 'bubu-selections',
  templateUrl: './selections.component.html',
  styleUrls: ['./selections.component.scss']
})
export class SelectionsComponent {
  @Select(ConfigurationState.selectedPaperFormat) selectedPaperFormat$: Observable<PaperFormatInterface>;
  @Select(ConfigurationState.selectedProductType) selectedProductType$: Observable<ProductTypeInterface>;
  @Select(ConfigurationState.selectedBinding) selectedBinding$: Observable<BindingTypeInterface>;
  @Select(ConfigurationState.selectedPaper) selectedPaper$: Observable<PaperInterface>;
  @Select(ConfigurationState.selectedPaperGroup) selectedPaperGroup$: Observable<PaperGroupInterface>;
  @Select(ConfigurationState.selectedSurface) selectedSurface$: Observable<PaperSurfaceInterface>;
  @Select(ConfigurationState.selectedSurfaceType) selectedSurfaceType$: Observable<SurfaceTypeInterface>;
  @Select(ConfigurationState.selectedOptions) selectedOptions$: Observable<OptionInterface[]>;
  @Select(ConfigurationState.selectedColorMode) selectedColorMode$: Observable<ColorModeInterface>;
  @Select(ContentState.isContentPDFSet) isContentPDFSet$: Observable<boolean>;


  constructor() {

  }


}
