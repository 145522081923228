import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from '../../shared/services/api.service';

import {
  IsCoverPDFOkay,
  IsCoverPDFSet,
  SetCoverPDF,
  SetCoverPDFDate,
  SetCoverPDFErrors,
  SetCoverPDFOriginalFilename,
  SetCoverPDFPageCount,
  SetCoverPDFSize
} from './cover.actions';
import { PageSizeInterface } from '../../../../../../shared/interfaces/page-size.interface';
import { CoverStateModel } from './cover-state.model';

const defaultCoverState = (): CoverStateModel => {
  return <CoverStateModel>{
    coverPDFSet: false,
    coverPDFOk: null,
    coverPDFOriginalFilename: null,
    coverPDFSize: null,
    coverPDFDate: null,
    coverPDF: null,
    showLogoOnCover: false,
    coverPDFPageCount: null,
    coverPDFErrors: [],
  }
};

@State<CoverStateModel>({
  name: 'coverState',
  defaults: defaultCoverState(),
})

export class CoverState implements NgxsOnInit {
  constructor(private apiService: ApiService) {
  }


  @Selector()
  static isCoverPDFSet(state: CoverStateModel): boolean {
    return state.coverPDFSet;
  }

  @Selector()
  static getCoverPDFSize(state: CoverStateModel): PageSizeInterface {
    return state.coverPDFSize;
  }

  @Selector()
  static getCoverPDFDate(state: CoverStateModel): Date {
    return state.coverPDFDate;
  }

  @Selector()
  static getCoverPDFOriginalFileName(state: CoverStateModel): string {
    return state.coverPDFOriginalFilename;
  }

  @Selector()
  static getCoverPDFErrors(state: CoverStateModel): any[] {
    return state.coverPDFErrors;
  }

  @Selector()
  static getCoverPDFPageCount(state: CoverStateModel): number {
    return state.coverPDFPageCount;
  }

  @Selector()
  static getCoverPDF(state: CoverStateModel): string {
    return state.coverPDF;
  }

  @Action(IsCoverPDFSet)
  setIsContentPDF(context: StateContext<CoverStateModel>, { isCoverPDFSet }: IsCoverPDFSet
  ) {
    return context.patchState({ coverPDFSet: isCoverPDFSet });
  }

  @Action(IsCoverPDFOkay)
  setIsCoverPDFOkay(context: StateContext<CoverStateModel>, { isCoverPDFOkay }: IsCoverPDFOkay
  ) {
    return context.patchState({ coverPDFOk: isCoverPDFOkay });
  }

  @Action(SetCoverPDFOriginalFilename)
  setCoverPDFOriginalFilename(context: StateContext<CoverStateModel>, { coverPDFOriginalFilename }: SetCoverPDFOriginalFilename
  ) {
    return context.patchState({ coverPDFOriginalFilename: coverPDFOriginalFilename });
  }

  @Action(SetCoverPDFSize)
  setCoverPDFSize(context: StateContext<CoverStateModel>, { coverPDFSize }: SetCoverPDFSize
  ) {
    return context.patchState({ coverPDFSize });
  }

  @Action(SetCoverPDFDate)
  setCoverPDFDate(context: StateContext<CoverStateModel>, { coverPDFDate }: SetCoverPDFDate
  ) {
    return context.patchState({ coverPDFDate });
  }

  @Action(SetCoverPDFPageCount)
  setCoverPDFPageCount(context: StateContext<CoverStateModel>, { coverPDFPageCount }: SetCoverPDFPageCount
  ) {
    return context.patchState({ coverPDFPageCount: coverPDFPageCount });
  }

  @Action(SetCoverPDFErrors)
  setCoverPDFErrors(context: StateContext<CoverStateModel>, { coverPDFErrors }: SetCoverPDFErrors
  ) {
    return context.patchState({ coverPDFErrors });
  }

  @Action(SetCoverPDF)
  setCoverPDF(context: StateContext<CoverStateModel>, { coverPDF }: SetCoverPDF
  ) {
    return context.patchState({ coverPDF });
  }

  ngxsOnInit({ dispatch }: StateContext<CoverState>): void | any {
    // dispatch(new SetPaperFormats());
  }


}
