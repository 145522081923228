import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {SetSurfaces} from '../../state/surface.actions';

@Component({
  selector: 'bubu-surface-container',
  templateUrl: './surface-container.component.html',
  styleUrls: ['./surface-container.component.scss'],
})
export class SurfaceContainerComponent implements OnInit {

  constructor(private readonly store: Store) {
    this.store.dispatch(new SetSurfaces());
  }

  ngOnInit() {
  }

}
