import {Selector, State, Store, Action, StateContext} from '@ngxs/store';
import {SURFACE} from '../config/surface';
import {SurfaceStateModel} from './surface-state.model';
import {SurfaceTypeInterface} from '../interfaces/surface-type.interface';
import {SetSurfaces} from './surface.actions';
import {BookConfigurationsService} from '../../../../../../shared/services/book-configurations.service';
import {ConfigurationState} from '../../shared/state/configuration/configuration.state';

const defaultSurfaceState = (): SurfaceStateModel => {
    return <SurfaceStateModel>{
        surfaceTypes: SURFACE
    };
};

@State<SurfaceStateModel>({
    name: 'surfaceState',
    defaults: defaultSurfaceState(),
})

export class SurfaceState {
    static store: Store;

    constructor(private readonly store: Store) {
        SurfaceState.store = store;
    }

    @Action(SetSurfaces)
    setSurfaces(context: StateContext<SurfaceStateModel>) {
        const productType = this.store.selectSnapshot(ConfigurationState.selectedProductType);
        const binding = this.store.selectSnapshot(ConfigurationState.selectedBinding);
        const availableSurfaces = BookConfigurationsService.getAvailableCoverSurfaces(productType.id, binding.id);

        return context.patchState({surfaceTypes: this.buildSurfaceTypeArray(availableSurfaces)});
    }

    private buildSurfaceTypeArray(availableSurfaces: string[]): SurfaceTypeInterface[] {

        const surfaceTypes: SurfaceTypeInterface[] = [];
        for (const surface of SURFACE) {
            if (availableSurfaces.includes(surface.id)) {
                surfaceTypes.push(surface);
            }
        }
        return surfaceTypes;
    }

    @Selector()
    static surfaceTypes(state: SurfaceStateModel): SurfaceTypeInterface[] {
        return state.surfaceTypes;
    }

}
