import { Component, Output, EventEmitter, Input } from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {TaxEnum} from '../../../../../../../shared/enums/tax.enum';
import {ConfigurationState} from '../../state/configuration/configuration.state';
import {SelectTax} from '../../state/configuration/configuration.action';

@Component({
    selector: 'bubu-usage',
    templateUrl: './tax.component.html',
    styleUrls: ['./tax.component.scss']
})
export class TaxComponent {
  @Input() isForCalculator = false;

  taxIds = [];
    selectedTax;

    @Output()
    taxChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(private readonly store: Store) {
        const taxValues = [];
        this.taxIds = [];
        for (const name in TaxEnum) {
            this.taxIds.push(name);
            taxValues[TaxEnum[name]] = name;
        }
        this.selectedTax = taxValues[this.store.selectSnapshot(ConfigurationState.selectedTax)];
    }

    onRadioChanged(id) {
        this.store.dispatch(new SelectTax(TaxEnum[id]));
        this.taxChange.emit(TaxEnum[id]);
    }

}
