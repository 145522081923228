import { CoverModeInterface } from '../interfaces/cover-mode.interface';
import { CoverModesEnum } from '../../../../../../shared/enums/cover-modes.enum';

export const COVER_MODES: CoverModeInterface[] =
  [
    {
      id: CoverModesEnum.ONE_FILE,
      description: 'CONTENT.COVER-MODE-ONE-FILE-DESCRIPTION'
    },
    {
      id: CoverModesEnum.SEPARATE,
      description: 'CONTENT.COVER-MODE-SEPARATE-DESCRIPTION'
    },
  ];

