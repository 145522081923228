import {Component, OnInit} from '@angular/core';
import {Store, Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ApiService} from '../../../shared/services/api.service';
import {ConfigurationState} from '../../../shared/state/configuration/configuration.state';
import {SetQuantity} from '../../../shared/state/configuration/configuration.action';
import {Price} from '../../../../../../../shared/classes/price';
import {
    SetCartButtonEnabled,
    SetCartButtonVisible
} from '../../../shared/state/gui/app-config.actions';
import {PriceState} from '../../../shared/state/price/price.state';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {PreviewComponent} from '../../../shared/components/preview/preview.component';

@Component({
    selector: 'bubu-summary-container',
    templateUrl: './summary-container.component.html',
    styleUrls: ['./summary-container.component.scss']
})
export class SummaryContainerComponent implements OnInit {

    @Select(PriceState.getPrice) price$: Observable<Price>;
    projectUrl: string;
    previewUrl: string;

    title: string;

    constructor(private readonly store: Store, private readonly apiService: ApiService) {
        this.store.dispatch(new SetCartButtonVisible(true));
        this.store.dispatch(new SetCartButtonEnabled(false));
        const projectId = this.store.selectSnapshot(ConfigurationState.getProjectId);
        this.projectUrl = window.location.href.replace(window.location.pathname, '');
        if (!this.projectUrl.includes('?projectId')) {
            this.projectUrl += '?projectId=' + projectId;
        }
        
        this.previewUrl = PreviewComponent.buildThumbnailURL(projectId, INTENTS.COVER, 1, 1);
        this.title = this.store.selectSnapshot(ConfigurationState.getProjectTitle);
    }

    setTitle(value) {
        if (value) {
            this.store.dispatch(new SetCartButtonEnabled(true));
        }
    }

    ngOnInit() {
        this.apiService.saveUserConfig(this.store.snapshot()).toPromise();
    }

    setQuantity(event) {
        this.store.dispatch(new SetQuantity(event));
    }

    setErrors(errors) {
        if (errors.length > 0 || !this.title) {
            this.store.dispatch(new SetCartButtonEnabled(false));
        } else {
            this.store.dispatch(new SetCartButtonEnabled(true));
        }
    }
}
