import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InnerContainerComponent} from '../main/containers/inner-container/inner-container.component';
import {PriceComponent} from '../shared/components/price/price.component';
import {SelectionsComponent} from '../shared/components/selections/selections.component';
import {SurfaceContainerComponent} from './containers/surface-container/surface-container.component';
import {BookErrorGuard} from '../shared/guards/book-error.guard';


const surfaceRoutes: Routes = [
    {
        path: 'surface',
        component: InnerContainerComponent,
        canActivate: [BookErrorGuard],
        data: {bookErrorModules: ['paper', 'content', 'cover']},
        children: [
            {
                path: '',
                component: SurfaceContainerComponent,
            },
            {
                path: '',
                outlet: 'additional-information',
                component: SelectionsComponent,
            },
            {
                path: '',
                outlet: 'additional-price',
                component: PriceComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(surfaceRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class SurfaceRoutingModule {
}
