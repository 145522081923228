import * as configurations from '../config/configurations.json';
import {BookBinding, BookMontage} from '../enums/book-attributes.enum';
import {FormatInterface} from '../interfaces/format.interface';
import {CoverModesEnum} from '../enums/cover-modes.enum';

export class BookConfigurationsService {

    static isCoverModeAvailable(montage: BookMontage, binding: BookBinding): boolean {
        return configurations.coverMode.available[montage] && configurations.coverMode.available[montage][binding];
    }

    static getCoverSubstrate(montage: BookMontage): string {
        return configurations.coverSubstrates[montage];
    }

    static getAvailableBindings(montage: BookMontage): string[] {
        return configurations.bindings[montage];
    }

    static getAvailableFormats(montage: BookMontage, binding: BookBinding): FormatInterface[] {
        const result = [];

        if (!configurations.format.available[montage] || !configurations.format.available[montage][binding]) {
            return result;
        }

        for (const formatName of configurations.format.available[montage][binding]) {
            const definition = configurations.format.definitions[formatName];
            if (!definition) {
                throw new Error(`No definition found for ${formatName}!`);
            }
            const format: FormatInterface = {
                height: definition[1],
                name: formatName,
                width: definition[0],
            };
            result.push(format);
        }
        return result;
    }

    static getAvailableCoverSurfaces(montage: BookMontage, binding: BookBinding): string[] {
        return configurations.surfaces[montage][binding].cover;
    }

    /**
     * simply returns all available paper formats and availabilities
     */
    static getAllPaperFormats(): object {
        return configurations.format;
    }

    /**
     * returns the exact pagecount cover pdfs must have
     */
    static getRequiredCoverPageCount(coverMode: CoverModesEnum, montage: BookMontage, binding: BookBinding): number {
        let validCoverPageCount = configurations.coverPageCounts.default;
        const coverModeCfg = configurations.coverPageCounts.coverMode;
        if (coverModeCfg[coverMode] && coverModeCfg[coverMode][montage] && coverModeCfg[coverMode][montage]) {
            validCoverPageCount = configurations.coverPageCounts.coverMode[coverMode][montage][binding];
        }
        return validCoverPageCount;
    }

    static getAvailableOptions(montage: BookMontage, binding: BookBinding): string[] {
        let availableOptions = [];
        if (configurations.options.available[montage] && configurations.options.available[montage][binding]) {
            availableOptions = configurations.options.available[montage][binding];
        }
        return configurations.options.available.all
            ? configurations.options.available.all.concat(availableOptions)
            : availableOptions;
    }

    static getAllOptionDefinitions() {
        return configurations.options.definitions;
    }

    static getOptionDefinition(optionKey: string) {
        return configurations.options.definitions[optionKey];
    }

    /**
     * returns unfiltered content papers
     */
    static getContentPapers(montage: BookMontage, binding: BookBinding): string[] {
        return configurations.contentPapers[montage][binding];
    }

    /**
     * returns whole format configurations
     */
    static getFormatConfig() {
        return configurations.format;
    }
}