import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {PaperStateModel} from './paper-state.model';
import {ApiService} from '../../shared/services/api.service';
import {SetPaper} from './paper.actions';
import {PaperSurfaceInterface} from '../interfaces/paper-surface.interface';
import {PAPER_SURFACE_TYPES} from '../configs/paper-surface-types';
import {PaperGroupInterface} from '../interfaces/paper-group.interface';
import {ConfigurationState} from '../../shared/state/configuration/configuration.state';
import {PAPER_GROUP_TYPES} from '../configs/paper-group-types';

const defaultPaperState = (): PaperStateModel => {
    return <PaperStateModel>{
        paperGroups: PAPER_GROUP_TYPES,
        surfaces: [],
    };
};

@State<PaperStateModel>({
    name: 'paperState',
    defaults: defaultPaperState(),
})

export class PaperState {

    static store: Store;

    constructor(private apiService: ApiService, private readonly store: Store) {
        PaperState.store = store;
    }

    @Selector()
    static filteredSurfaces(state: PaperStateModel): PaperSurfaceInterface[] {
        return state.surfaces;
    }

    @Action(SetPaper)
    setPaper({patchState}: StateContext<PaperStateModel>) {
        const productType = this.store.selectSnapshot(ConfigurationState.selectedProductType);
        const binding = this.store.selectSnapshot(ConfigurationState.selectedBinding);
        if (binding && productType) {
            return this.apiService.getPapers(productType.id, binding.id, 1).pipe(
                tap(data => patchState({
                    paperGroups: this.buildPaperGroupsArray(data),
                    surfaces: this.buildPaperSurfaceArray(data),
                })),
            );
        }
        return [];
    }

    private buildPaperSurfaceArray(papersRaw: any): PaperSurfaceInterface[] {

        const returnArray: PaperSurfaceInterface[] = [];

        for (const paperSurface of PAPER_SURFACE_TYPES) {

            let foundSurface = false;
            for (const paper of papersRaw) {
                if (paper.surface === paperSurface.id) {
                    foundSurface = true;
                    break;
                }
            }

            if (foundSurface) {
                returnArray.push(paperSurface);
            }
        }

        return returnArray;
    }

    private buildPaperGroupsArray(papersRaw: any): PaperGroupInterface[] {

        const returnArray: PaperGroupInterface[] = [];

        for (const paperGroup of PAPER_GROUP_TYPES) {

            const papers = [];
            for (const paper of papersRaw) {
                if (paper.nameGroup === paperGroup.id) {
                    papers.push({
                        id: paper.code,
                        name: `${paper.weight} g/m&#178;`,
                        weight: paper.weight,
                    });
                }

            }
            if (papers.length > 0) {
                paperGroup.papers = papers;
                returnArray.push(paperGroup);
            }

        }
        return returnArray;
    }

}
