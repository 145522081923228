import { Component, Input } from '@angular/core';
import { MAIN_MENU_ENTRIES } from '../../state/gui/config/main-menu-entries';

@Component({
  selector: 'bubu-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent {
  currentPage = '';
  @Input() currentUrl;

  constructor() {
  }

  buildPageName(url) {
    if (url) {
      const currentPageEntry = MAIN_MENU_ENTRIES.find(menuEntry => menuEntry.link === url.replace('/', ''))
      if (currentPageEntry) {
        return currentPageEntry.additionalName ? currentPageEntry.additionalName : currentPageEntry.name;
      } else {
        return '';
      }
    }
  }
}
