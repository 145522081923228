export const environment = {
  development: false,
  gaTrackingID: 'UA-9784361-6',
  production: false,
  backendUrl: 'https://api.testing.pdf-to-book.bookfactory.ch',
  staging: true,
  priceCalculator: true,
  calculatorUrl: '/calculator'
};

