import { PageSizeInterface } from '../../../../../../shared/interfaces/page-size.interface';

export class IsContentPDFSet {
  static readonly type = '[Content] Set content PDF state';

  constructor(public isContentPDFSet: boolean) {
  }
}

export class IsContentPDFOkay {
  static readonly type = '[Content] Set content PDF okay';

  constructor(public isContentPDFOkay: boolean) {
  }
}

export class SetContentPDFOriginalFilename {
  static readonly type = '[Content] Set content PDF original filename';

  constructor(public contentPDFOriginalFilename: string) {
  }
}

export class SetContentPDFSize {
  static readonly type = '[Content] Set content PDF size';

  constructor(public contentPDFSize: PageSizeInterface) {
  }
}

export class SetContentPDFDate {
  static readonly type = '[Content] Set content PDF date';

  constructor(public contentPDFDate: Date) {
  }
}

export class SetSamplePageCount {
  static readonly type = '[Content] Set sample page count';

  constructor(public samplePageCount: number) {
  }
}

export class SetContentPDFPageCount {
  static readonly type = '[Content] Set Content PDF page count';

  constructor(public contentPDFPageCount: number) {
  }
}

export class SetContentPDFErrors {
  static readonly type = '[Content] Set Content PDF errors';

  constructor(public contentPDFErrors: any[]) {
  }
}

export class SetContentPDF {
  static readonly type = '[Content] Set Content PDF';

  constructor(public contentPDF: string) {
  }
}
