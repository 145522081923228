import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {append, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {ConfigurationStateModel} from '../../../../../../../shared/interfaces/configuration-state.model';
import {CoverModesEnum} from '../../../../../../../shared/enums/cover-modes.enum';
import {ProductTypeInterface} from '../../../product/interfaces/product-type.interface';
import {PaperFormatInterface} from '../../../product/interfaces/paper-format.interface';
import {BindingTypeInterface} from '../../../binding/interfaces/binding-type.interface';
import {ColorModeInterface} from '../../../content/interfaces/color-mode.interface';
import {CoverModeInterface} from '../../../content/interfaces/cover-mode.interface';

import {
  AddError,
  DeselectOption, RemoveError,
  SelectBindingType,
  SelectColorMode,
  SelectCoverMode,
  SelectOption,
  SelectPaper,
  SelectPaperFormat,
  SelectPaperGroup,
  SelectPaperSurface,
  SelectProductType,
  SelectSurface, SelectTax, SetCartId, SetIsCalculator,
  SetProjectId,
  SetProjectTitle,
  SetQuantity,
  SetUserConfiguration, ValidateBook
} from './configuration.action';
import {BookInterface} from '../../../../../../../shared/interfaces/book.interface';
import {PaperGroupInterface} from '../../../paper/interfaces/paper-group.interface';
import {PaperInterface} from '../../../paper/interfaces/paper.interface';
import {PaperSurfaceInterface} from '../../../paper/interfaces/paper-surface.interface';
import {BookContentColor} from '../../../../../../../shared/enums/book-content-color.enum';
import {SurfaceTypeInterface} from '../../../surface/interfaces/surface-type.interface';
import {OptionInterface} from '../../../../../../../shared/interfaces/option.interface';
import {ContentState} from '../../../content/state/content.state';
import {BookBinding, BookMontage} from '../../../../../../../shared/enums/book-attributes.enum';
import {tap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {TaxEnum} from '../../../../../../../shared/enums/tax.enum';
import {BookErrorsEnum} from '../../../../../../../shared/enums/book-errors.enum';
import {
    BOOK_MODULE_ERRORS,
    BookModuleErrors
} from '../../../../../../../shared/config/book-module-errors';
import {CoverState} from '../../../cover/state/cover.state';
import {ContentStateModel} from '../../../content/state/content-state.model';
import {
    BookErrorInterface,
    BookErrorSource
} from '../../../../../../../shared/interfaces/book-validate-return.interface';
import {SetContentPDFPageCount} from '../../../content/state/content.actions';


const defaultConfigurationState = (): ConfigurationStateModel => {
    return <ConfigurationStateModel>{
        projectId: null,
        isCalculator: false,
        cartId: null,
        quantity: 1,
        projectTitle: null,
        selectedProductType: BookMontage.HANG,
        selectedPaperFormat: 'A5-Portrait',
        selectedBinding: BookBinding.GLUE,
        selectedColorMode: BookContentColor.CMYK,
        selectedCoverMode: CoverModesEnum.ONE_FILE,
        selectedPaper: 'Xper-120',
        selectedPaperGroup: '',
        selectedSurface: '',
        selectedOptions: [],
        selectedSurfaceType: '',
        selectedTax: TaxEnum.PRIVATE,
        errors: [],
    };
};

@State<ConfigurationStateModel>({
    name: 'configurationState',
    defaults: defaultConfigurationState(),
})

export class ConfigurationState {
    static store: Store;

    constructor(private store: Store,
                private readonly apiService: ApiService) {
        ConfigurationState.store = store;
    }

    @Selector()
    static selectedProductType(state: ConfigurationStateModel): ProductTypeInterface {
        return ConfigurationState.store.selectSnapshot(state => state.productState.productTypes)
            .find(productType => productType.id === state.selectedProductType);
    }

    @Selector()
    static selectedPaperFormat(state: ConfigurationStateModel): PaperFormatInterface {
        return ConfigurationState.store.selectSnapshot(state => state.productState.paperFormats)
            .find(paperFormat => paperFormat.id === state.selectedPaperFormat);
    }

    @Selector()
    static selectedBinding(state: ConfigurationStateModel): BindingTypeInterface {
        return ConfigurationState.store.selectSnapshot(state => state.bindingState.bindingTypes)
            .find(bindingType => bindingType.id === state.selectedBinding);
    }

    @Selector()
    static selectedColorMode(state: ConfigurationStateModel): ColorModeInterface {
        return ConfigurationState.store.selectSnapshot(state => state.contentState.colorModes)
            .find(colorMode => colorMode.id === state.selectedColorMode);
    }


    @Selector()
    static selectedCoverMode(state: ConfigurationStateModel): CoverModeInterface {
        return ConfigurationState.store.selectSnapshot(state => state.contentState.coverModes)
            .find(coverMode => coverMode.id === state.selectedCoverMode);
    }

    @Selector([ContentState, CoverState])
    static getBook(state: ConfigurationStateModel): Partial<BookInterface> {
        return {
            binding: this.selectedBinding(state).id,
            color: this.selectedColorMode(state).id,
            contentPDFSize: this.store.selectSnapshot(ContentState.getContentPDFSize),
            coverMode: this.selectedCoverMode(state).id,
            coverPages: this.store.selectSnapshot(CoverState.getCoverPDFPageCount),
            coverPDFSize: this.store.selectSnapshot(CoverState.getCoverPDFSize),
            height: this.selectedPaperFormat(state) ? this.selectedPaperFormat(state).height : 100,
            montage: this.selectedProductType(state).id,
            options: this.selectedOptions(state) || [],
            pages: this.store.selectSnapshot(ContentState.getContentPDFPageCount)
                ? this.store.selectSnapshot(ContentState.getContentPDFPageCount) : 16,
            paperCode: this.selectedPaper(state) ? this.selectedPaper(state).id : '',
            surface: this.selectedSurfaceType(state) ? this.selectedSurfaceType(state).id : '',
            tax: this.selectedTax(state),
            width: this.selectedPaperFormat(state) ? this.selectedPaperFormat(state).width : 100,
        };
    }

    @Selector()
    static getProjectId(state: ConfigurationStateModel): string {
        return state.projectId;
    }

    @Selector()
    static getCartId(state: ConfigurationStateModel): string {
        return state.cartId;
    }

    @Selector()
    static filteredPaperGroups(state: ConfigurationStateModel): PaperGroupInterface[] {
        const returnArray: PaperGroupInterface[] = [];
        const paperGroups = ConfigurationState.store.selectSnapshot(state => state.paperState.paperGroups);
        for (const paperGroup of paperGroups) {
            if (paperGroup.surface === state.selectedSurface) {
                returnArray.push(paperGroup);
            }
        }
        return returnArray;
    }

    @Selector()
    static selectedPaper(state: ConfigurationStateModel): PaperInterface {
        const paperGroups = ConfigurationState.store.selectSnapshot(state => state.paperState.paperGroups);

        let selectedPaper;
        for (const paperGroup of paperGroups) {
            selectedPaper = paperGroup.papers.find(paper => paper.id === state.selectedPaper);
            if (selectedPaper !== undefined) {
                return selectedPaper;
            }
        }
        return null;
    }

    @Selector()
    static selectedPaperGroup(state: ConfigurationStateModel): PaperGroupInterface {
        const paperGroups = ConfigurationState.store.selectSnapshot(state => state.paperState.paperGroups);
        return paperGroups.find(paperGroup => paperGroup.id === state.selectedPaperGroup);
    }

    @Selector()
    static selectedSurface(state: ConfigurationStateModel): PaperSurfaceInterface {
        const surfaces = ConfigurationState.store.selectSnapshot(state => state.paperState.surfaces);
        return surfaces.find(surface => surface.id === state.selectedSurface);
    }

    @Selector()
    static selectedOptions(state: ConfigurationStateModel): OptionInterface[] {
        return state.selectedOptions;
    }

    @Selector()
    static selectedSurfaceType(state: ConfigurationStateModel): SurfaceTypeInterface {
        return ConfigurationState.store.selectSnapshot(state => state.surfaceState.surfaceTypes)
            .find(surfaceType => surfaceType.id === state.selectedSurfaceType);
    }

    @Selector()
    static getQuantity(state: ConfigurationStateModel): number {
        return state.quantity;
    }

    @Selector()
    static getProjectTitle(state: ConfigurationStateModel): string {
        return state.projectTitle;
    }

    @Selector()
    static selectedTax(state: ConfigurationStateModel): number {
        return state.selectedTax;
    }

    @Selector()
    static getErrors(state: ConfigurationStateModel): BookErrorInterface[] {
        return state.errors;
    }

    @Action(SetProjectId)
    setProjectId(context: StateContext<ConfigurationStateModel>, {projectId}: SetProjectId) {
        return context.patchState({projectId});
    }

    @Action(SetCartId)
    setCartId(context: StateContext<ConfigurationStateModel>, {cartId}: SetCartId) {
        return context.patchState({cartId});
    }
   @Action(SetIsCalculator)
    setIsCalculator(context: StateContext<ConfigurationStateModel>, {isCalculator}: SetIsCalculator) {
    return context.patchState({isCalculator});
   }

    @Action(SelectProductType)
    selectProductType(context: StateContext<ConfigurationStateModel>, {productId}: SelectProductType) {
        return context.patchState({selectedProductType: productId});
    }

    @Action(SelectPaperFormat)
    selectPaperFormat(context: StateContext<ConfigurationStateModel>, {formatId}: SelectPaperFormat) {
        return context.patchState({selectedPaperFormat: formatId});
    }

    @Action(SelectBindingType)
    selectBindingType(context: StateContext<ConfigurationStateModel>, {bindingId}: SelectBindingType) {
        return context.patchState({selectedBinding: bindingId});
    }

    @Action(SelectColorMode)
    selectColorMode(context: StateContext<ConfigurationStateModel>, {colorModeId}: SelectColorMode) {
        return context.patchState({selectedColorMode: colorModeId});
    }

    @Action(SelectCoverMode)
    async selectCoverMode(context: StateContext<ConfigurationStateModel>, {coverModeId}: SelectCoverMode) {
        const res = context.patchState({selectedCoverMode: coverModeId});
        if (this.store.selectSnapshot(ContentState.isContentPDFSet)) {
            const response = await this.apiService.changeCoverMode(
                this.store.selectSnapshot(ConfigurationState.getProjectId),
                coverModeId
            ).toPromise();

            this.store.dispatch(new SetContentPDFPageCount(response.pages));
        }
        return res;
    }


    @Action(SelectPaper)
    selectPaper(context: StateContext<ConfigurationStateModel>, {paperId}: SelectPaper) {
        return context.patchState({selectedPaper: paperId});
    }

    @Action(SelectPaperGroup)
    selectPaperGroup(context: StateContext<ConfigurationStateModel>, {paperGroupId}: SelectPaperGroup) {
        return context.patchState({selectedPaperGroup: paperGroupId});
    }

    @Action(SelectPaperSurface)
    selectPaperSurface(context: StateContext<ConfigurationStateModel>, {surfaceId}: SelectPaperSurface) {
        return context.patchState({selectedSurface: surfaceId});
    }

    @Action(SelectSurface)
    selectSurface(context: StateContext<ConfigurationStateModel>, {surfaceId}: SelectSurface) {
        return context.patchState({selectedSurfaceType: surfaceId});
    }

    @Action(SelectOption)
    selectOption(context: StateContext<ConfigurationStateModel>, {optionId, optionValueId}: SelectOption) {
        const option = context.getState().selectedOptions.find(option => option.name === optionId);
        const optionInterface: OptionInterface = {
            name: optionId,
            selected: optionValueId
        };
        if (option) {
            return context.setState(
                patch({
                    selectedOptions: updateItem(option => option.name === optionId, optionInterface)
                })
            );
        } else {
            return context.setState(
                patch({
                    selectedOptions: append([optionInterface])
                })
            );
        }
    }

    @Action(DeselectOption)
    deselectOption(context: StateContext<ConfigurationStateModel>, {optionId}: DeselectOption) {
        return context.setState(
            patch({
                selectedOptions: removeItem(option => option.name === optionId)
            })
        );
    }

    @Action(SetQuantity)
    setQuantity(context: StateContext<ConfigurationStateModel>, {quantity}: SetQuantity) {
        return context.patchState({quantity});
    }

    @Action(SetProjectTitle)
    setProjectTitle(context: StateContext<ConfigurationStateModel>, {projectTitle}: SetProjectTitle) {
        return context.patchState({projectTitle});
    }

    @Action(SetUserConfiguration)
    setUserConfiguration({setState}: StateContext<ConfigurationStateModel>, {projectId}: SetUserConfiguration) {
        return this.apiService.getUserConfig(projectId).pipe(
            tap(userConfiguration => this.store.reset(userConfiguration),
            ));
    }

    @Action(SelectTax)
    selectTax(context: StateContext<ConfigurationStateModel>, {tax}: SelectTax) {
        return context.patchState({selectedTax: tax});
    }

    @Action(AddError)
    addError(context: StateContext<ConfigurationStateModel>, {error}: AddError) {
        const foundError = context.getState().errors.find(errors => errors.id === error.id);


        if (!foundError) {
            // error.source = BookErrorSource.FRONTEND;
            return context.setState(
                patch({
                    errors: append([error])
                })
            );
        }
    }

    @Action(RemoveError)
    removeError(context: StateContext<ConfigurationStateModel>, {errorId}: RemoveError) {
        return context.setState(
            patch({
                errors: removeItem(error => error.id === errorId)
            })
        );
    }

    @Action(ValidateBook)
    validateBook(ctx: StateContext<ConfigurationStateModel>) {
        return this.apiService.validate(this.store.selectSnapshot(ConfigurationState.getBook)).pipe(
            // @ts-ignore
            tap(data => ctx.patchState({
                errors: this.filterErrors(data, ctx.getState().errors),
            })),
        );
    }

    private filterErrors(rawResponse, stateErrors): [] {
        rawResponse = rawResponse ? rawResponse.errors : [];

        for (const rawError of rawResponse) {
            rawError.source = BookErrorSource.BACKEND;
        }

        for (const error of stateErrors) {
            if (error.source === BookErrorSource.FRONTEND) {
                // push errors from frontend again so that they don't get overwritten
                rawResponse.push(error);
            }
        }

        return rawResponse;
    }
}
