import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {Select} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {ContentState} from '../../../content/state/content.state';
import {AppConfigState} from '../../state/gui/app-config.state';
import {PageSizeInterface} from '../../../../../../../shared/interfaces/page-size.interface';
import {CoverState} from '../../../cover/state/cover.state';

@Component({
    selector: 'bubu-pdf-info',
    templateUrl: './pdf-info.component.html',
    styleUrls: ['./pdf-info.component.scss']
})
export class PdfInfoComponent implements OnInit, OnDestroy {

    @Input()
    intent!: INTENTS;

    pdfDate: Date;
    pdfSize: PageSizeInterface = {width: 0, height: 0};
    pdfFileName = '';
    pageCount = 0;
    @Select(ContentState.getContentPDFOriginalFileName) contentPDFFilename$: Observable<string>;
    @Select(ContentState.getContentPDFDate) contentPDFDate$: Observable<Date>;
    @Select(ContentState.getContentPDFSize) contentPdfSize$: Observable<PageSizeInterface>;
    @Select(ContentState.getContentPDFPageCount) contentPdfPageCount$: Observable<number>;

    @Select(CoverState.getCoverPDFOriginalFileName) coverPDFFilename$: Observable<string>;
    @Select(CoverState.getCoverPDFDate) coverPDFDate$: Observable<Date>;
    @Select(CoverState.getCoverPDFSize) coverPdfSize$: Observable<PageSizeInterface>;
    @Select(CoverState.getCoverPDFPageCount) coverPdfPageCount$: Observable<number>;

    @Select(AppConfigState.selectedLanguage) selectedLanguage$: Observable<string>;

    private PDFFilenameSubscription: Subscription;
    private PDFDateSubscription: Subscription;
    private PDFSizeSubscription: Subscription;
    private PDFPageCountSubscription: Subscription;

    constructor() {

    }

    ngOnInit() {
        if (this.intent === INTENTS.CONTENT) {
            this.PDFFilenameSubscription = this.contentPDFFilename$.subscribe(filename => this.pdfFileName = filename);
            this.PDFDateSubscription = this.contentPDFDate$.subscribe(date => this.pdfDate = date);
            this.PDFSizeSubscription = this.contentPdfSize$.subscribe(size => this.pdfSize = size);
            this.PDFPageCountSubscription = this.contentPdfPageCount$.subscribe(count => this.pageCount = count);
        }
        if (this.intent === INTENTS.COVER) {
            this.PDFFilenameSubscription = this.coverPDFFilename$.subscribe(filename => this.pdfFileName = filename);
            this.PDFDateSubscription = this.coverPDFDate$.subscribe(date => this.pdfDate = date);
            this.PDFSizeSubscription = this.coverPdfSize$.subscribe(size => this.pdfSize = size);
            this.PDFPageCountSubscription = this.coverPdfPageCount$.subscribe(count => this.pageCount = count);

        }
    }

    ngOnDestroy() {
        this.PDFFilenameSubscription.unsubscribe();
        this.PDFDateSubscription.unsubscribe();
        this.PDFSizeSubscription.unsubscribe();
        this.PDFPageCountSubscription.unsubscribe();
    }

}
