import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {BookErrorComponent} from '../components/book-error/book-error.component';
import {ConfigurationState} from '../state/configuration/configuration.state';

@Injectable({
    providedIn: 'root'
})
export class BookErrorGuard implements CanActivate {

    constructor(private readonly store: Store) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (BookErrorComponent.filter(next.data.bookErrorModules, this.store.selectSnapshot(ConfigurationState.getErrors)).length === 0) {
            return true;
        }

        return false;
    }
}
