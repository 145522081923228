import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InnerContainerComponent} from '../main/containers/inner-container/inner-container.component';
import {AdditionalInformationComponent} from '../shared/components/additional-information/additional-information.component';
import {PaperFormatComponent} from './components/paper-format/paper-format.component';
import {PriceComponent} from '../shared/components/price/price.component';
import {AdditionalHintsService} from '../shared/services/additional-hints.service';
import {ProductContainerComponent} from './containers/product-container/product-container.component';
import {QuantityComponent} from '../shared/components/quantity/quantity.component';


const productRoutes: Routes = [
    {
        path: 'product',
        component: InnerContainerComponent,
        children: [
            {
                path: '',
                component: ProductContainerComponent,
            },
            {
                path: '',
                outlet: 'additional-quantity',
                component: QuantityComponent
            },
            {
                path: '',
                outlet: 'additional-information',
                component: PaperFormatComponent,

            },
            {
                path: '',
                outlet: 'additional-hints',
                component: AdditionalInformationComponent,
                resolve: {
                    additionalHints: AdditionalHintsService,
                },
            },
            {
                path: '',
                outlet: 'additional-price',
                component: PriceComponent
            },
        ],
    },
    // {
    //   path: 'product/:productId',
    //   component: InnerContainerComponent,
    //   children: [
    //     {
    //       path: '',
    //       component: ProductContainerComponent,
    //     },
    //     {
    //       path: '',
    //       outlet: 'additional-information',
    //       component: PaperFormatComponent,
    //
    //     },
    //     {
    //       path: '',
    //       outlet: 'additional-hints',
    //       component: AdditionalInformationComponent,
    //       resolve: {
    //         additionalHints: AdditionalHintsService,
    //       },
    //     },
    //     {
    //       path: '',
    //       outlet: 'additional-price',
    //       component: PriceComponent,
    //     },
    //   ],
    // },
];

@NgModule({
    imports: [
        RouterModule.forChild(productRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class ProductRoutingModule {
}
