export class SetLanguage {
  static readonly type = '[APP Config] Set language';

  constructor(public languageCode: string) {
  }
}

export class SetBackendConfig {
  static readonly type = '[APP Config] Set backend config';
}

export class SetCartButtonEnabled {
  static readonly type = '[APP Config] Set cart button enabled';

  constructor(public enabled: boolean) {
  }
}

export class SetCartButtonVisible {
  static readonly type = '[APP Config] Set cart button visible';

  constructor(public visible: boolean) {
  }
}

export class SetNextButtonEnabled {
  static readonly type = '[APP Config] Set next button enabled';

  constructor(public enabled: boolean) {
  }
}

export class SetAppVersion {
  static readonly type = '[APP Config] Set app version';

}
