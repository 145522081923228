import { Component, OnInit } from '@angular/core';
import { SetBindingTypes } from '../../../binding/state/binding.actions';
import { Select, Store } from '@ngxs/store';
import { SetPaper } from '../../../paper/state/paper.actions';
import { SetSurfaces } from '../../../surface/state/surface.actions';
import { SetOptions } from '../../../options/state/option.actions';

import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { SetProjectId, SetProjectTitle } from '../../../shared/state/configuration/configuration.action';
import { OfferPdfService } from '../../services/offer-pdf.service';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { Observable } from 'rxjs';
import { SetContentPDFPageCount } from '../../../content/state/content.actions';


@Component({
  selector: 'bubu-price-calculator',
  templateUrl: './price-calculator.component.html',
  styleUrls: ['./price-calculator.component.scss']
})
export class PriceCalculatorComponent implements OnInit {
  @Select(ConfigurationState.getProjectTitle) projectTitle$: Observable<string>;
  projectUrl: string;
  showProjectUrl = false;
  projectTitle;
  projectId;

  constructor(private readonly store: Store,
              private readonly route: ActivatedRoute,
              private readonly apiService: ApiService,
              private offerPdfService: OfferPdfService) {
    store.dispatch(new SetPaper());
    store.dispatch(new SetBindingTypes());
    store.dispatch(new SetSurfaces());
    store.dispatch(new SetOptions());
  }

  ngOnInit() {
    this.projectTitle$.subscribe(title => this.projectTitle = title);
    this.projectId = this.store.selectSnapshot(ConfigurationState.getProjectId);
    if (this.projectId) {
      this.showProjectUrl = true;
      this.generateProjectUrl();
    }
  }

  setProjectTitle() {
    if (this.projectTitle !== '') {
      this.store.dispatch(new SetProjectTitle(this.projectTitle));
    }
  }

  async saveConfig() {

    if (!this.projectId) {
      this.projectId = (await this.apiService.saveUserConfig(this.store.snapshot()).toPromise() as any).projectId;
    }
    this.store.dispatch(new SetProjectId(this.projectId));
    this.generateProjectUrl();
  }

  generatePDF() {
    this.offerPdfService.generatePDF();
  }

  private generateProjectUrl() {
    this.projectUrl = window.location.href.replace(window.location.pathname, '');
    if (!this.projectUrl.includes('?projectId')) {
      this.projectUrl += '?projectId=' + this.projectId;
    }
    this.showProjectUrl = true;
  }
}
