import {loader} from 'webpack';

const LOADING_IMAGE = 'assets/images/loading.png';

export class PagePreview {

    sourceWidth: number;
    sourceHeight: number;
    height: number;
    width: number;
    bleed: number;
    isDoublePage: boolean;
    additionalText: string;

    zoom: number;

    private canvas;
    private imagesLoaded = 0;
    private imagesToLoad = 0;

    private ratio = 1;

    constructor(sourceWidthMM,
                sourceHeightMM,
                widthMM,
                heightMM,
                bleedMM,
                isDoublePage = true,
                zoomMultiplier = 1,
                imagesToLoad = 2,
                additionalText = '') {
        this.sourceWidth = sourceWidthMM;
        this.sourceHeight = sourceHeightMM;
        this.width = widthMM;
        this.height = heightMM;
        this.applyZoom(zoomMultiplier);
        this.zoom = zoomMultiplier;
        this.bleed = bleedMM;
        this.isDoublePage = isDoublePage;
        this.imagesToLoad = imagesToLoad;
        this.additionalText = additionalText;

        if (this.imagesToLoad > 1 && !this.isDoublePage) {
            throw new Error('Only one image can be placed on single pages!');
        }

        this.initCanvas();
    }

    public applyZoom(zoom) {
        if (this.zoom) {
            this.width /= this.zoom;
            this.height /= this.zoom;
        }

        this.width *= zoom;
        this.height *= zoom;
        this.zoom = zoom;

        if (this.canvas) {
            this.canvas.style.width = this.width + 'px';
            this.canvas.style.height = this.height + 'px';
        }
    }

    addPageImage(imgSrc: string, position: string, greyScale = false, onLoadCallback = null) {

        this.drawLoadingImage(position);

        const imageObject = new Image();
        imageObject.crossOrigin = 'Anonymous';
        imageObject.onload = () => {
            this.imageOnLoad(imageObject, position, greyScale);
            if (onLoadCallback) {
                onLoadCallback();
            }
        };
        imageObject.onerror = (err) => {
            console.error(err);
        };
        imageObject.src = imgSrc;
    }

    getCanvas(): any {
        return this.canvas;
    }

    private initCanvas() {
        this.canvas = document.createElement('canvas');
        this.canvas.style.width = this.width + 'px';
        this.canvas.style.height = this.height + 'px';
        this.canvas.style.margin = '2px';
        this.canvas.style.backgroundColor = 'white';
    }

    private imageOnLoad(imageObject, position, greyScale = false): any {
        const ctx = this.canvas.getContext('2d');
        this.imagesLoaded++;

        let x = 0;
        if (this.imagesLoaded === 1) {
            // adjust canvas size with image size on first image loaded
            this.ratio = imageObject.width / this.sourceWidth;
            this.sourceHeight *= this.ratio;
            this.sourceWidth *= this.ratio;

            if (this.isDoublePage) {
                // remove inner bleed on double pages
                this.sourceWidth -= this.bleed * this.ratio;
                this.canvas.width = this.sourceWidth * 2;
            } else {
                this.canvas.width = this.sourceWidth;
            }
            this.canvas.height = this.sourceHeight;
        }

        switch (position) {
            case 'right':
                if (this.isDoublePage) {
                    x = (this.canvas.width / 2) - this.bleed * this.ratio ;
                }
                break;
        }

        this.drawImage(ctx, imageObject, x, 0, greyScale);

        if (this.imagesLoaded === this.imagesToLoad) {
            this.bleed *= this.ratio;

            if (this.isDoublePage) {
                this.drawMid(ctx);
            }

            // draw cut lines after last image is drawn
            this.drawCutLines(ctx);
        }

        if (this.additionalText) {
            // TODO draw text...
            // console.log('write', this.additionalText);
            // ctx.fillStyle = 'red';
            // ctx.font = '30px Arial';
            // ctx.fillText(this.additionalText,
            //     imageObject.width / 2,
            //     imageObject.height - imageObject.height / 4
            // );
            // ctx.fillText(this.additionalText, 10, 10);
        }

        return true;
    }

    public drawImage(ctx, imageObject, x, y, greyScale = false) {
        ctx.drawImage(imageObject, x, y);

        if (greyScale) {
            const imageData = ctx.getImageData(x, y, imageObject.width, imageObject.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
                // red
                data[i] = brightness;
                // green
                data[i + 1] = brightness;
                // blue
                data[i + 2] = brightness;
            }

            // overwrite original image
            ctx.putImageData(imageData, x, y);
        }

    }

    private drawCutLines(ctx) {
        ctx.setLineDash([20, 10]);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#AAAAA';
        ctx.fillStyle = '#E5E5E5';
        const width = this.isDoublePage ? this.sourceWidth * 2 : this.sourceWidth;

        // Top
        this.drawRect(ctx, 0, this.sourceHeight - this.bleed, width, this.bleed, 0.5);
        this.drawLine(ctx, this.bleed, this.bleed, width - this.bleed, this.bleed);

        // Right
        // avoid overlapping of two transparent rectangles
        this.drawRect(ctx, width - this.bleed, this.bleed, this.bleed, this.sourceHeight - this.bleed * 2, 0.5);
        this.drawLine(ctx, this.canvas.width - this.bleed, this.sourceHeight - this.bleed, width - this.bleed, this.bleed);

        // Bottom
        this.drawRect(ctx, 0, 0, width, this.bleed, 0.5);
        this.drawLine(ctx, this.bleed, this.sourceHeight - this.bleed, width - this.bleed, this.sourceHeight - this.bleed);

        // Left
        this.drawRect(ctx, 0, this.bleed, this.bleed, this.sourceHeight - this.bleed * 2, 0.5);
        this.drawLine(ctx, this.bleed, this.bleed, this.bleed, this.sourceHeight - this.bleed);
    }

    private drawMid(ctx) {
        let width = this.sourceWidth * 2;
        // draw mid line
        if (this.isDoublePage) {
            ctx.globalAlpha = 0.9;

            ctx.strokeStyle = '#747474';
            ctx.lineWidth = 2;
            width = (width / 2);
            this.drawLine(ctx, width, 0, width, this.sourceHeight);

            const gradientWidth = 40;

            // // gradient right
            // const grdRight = ctx.createLinearGradient(width / 2, this.bleed, width / 2 + gradientWidth, this.bleed);
            // grdRight.addColorStop(0, '#948f91');
            // grdRight.addColorStop(1, '#E5E5E5');
            //
            // ctx.fillStyle = grdRight;
            // ctx.fillRect(width / 2, 20, gradientWidth, this.sourceHeight - this.bleed * 2);

            // gradient left
            // const grdLeft = ctx.createLinearGradient(width / 2 - gradientWidth, this.bleed, width / 2, this.bleed);
            // grdLeft.addColorStop(0, '#E5E5E5');
            // grdLeft.addColorStop(1, '#948f91');
            //
            // ctx.fillStyle = grdLeft;
            // ctx.fillRect(width / 2 - gradientWidth, this.bleed, gradientWidth, this.sourceHeight - this.bleed * 2);
            ctx.globalAlpha = 1;
        }
    }

    private drawLoadingImage(position: string) {
        const ctx = this.canvas.getContext('2d');
        const loaderImage = new Image();

        const size = 20;

        let x = this.canvas.width / 4 - size / 2;
        switch (position) {
            case 'right':
                x = (this.canvas.width / 4) * 3;
                break;
        }

        loaderImage.onload = () => {
            ctx.drawImage(loaderImage, x, this.canvas.height / 2 - size / 2, size, size);
        };
        loaderImage.src = LOADING_IMAGE;
    }

    private drawRect(ctx, x, y, width, height, alpha = 1.0) {
        ctx.globalAlpha = alpha;
        ctx.beginPath();
        ctx.fillRect(x, y, width, height);
        ctx.stroke();
        ctx.globalAlpha = 1.0;
    }

    private drawLine(ctx, x1, y1, x2, y2) {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }
}