import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PriceCalculatorComponent } from './modules/price-calculator/components/price-calculator/price-calculator.component';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: environment.calculatorUrl.replace('/', ''),
    component: PriceCalculatorComponent,
    pathMatch: 'full',
  },
  { path: '', redirectTo: 'product', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
