import {BookBinding, BookMontage} from '../../../../../../shared/enums/book-attributes.enum';
import {ProductTypeInterface} from '../interfaces/product-type.interface';

export const PRODUCT_TYPES: ProductTypeInterface[] =
  [
    {
      availableBindings: [BookBinding.GLUE, BookBinding.SEW, BookBinding.FLAT],
      id: BookMontage.HANG,
      name: 'TYPE.HARDCOVER',
      description: 'TYPE.HARDCOVER_DESCRIPTION',
      image: 'hardcover.png',
    },
    {
      availableBindings: [BookBinding.GLUE, BookBinding.SEW],
      id: BookMontage.FIXING,
      name: 'TYPE.SOFTCOVER',
      description: 'TYPE.SOFTCOVER_DESCRIPTION',
      image: 'softcover.png',
    },
    {
      availableBindings: [BookBinding.WIREO],
      id: BookMontage.WIREO,
      name: 'TYPE.WIRE_O_BINDING',
      description: 'TYPE.WIRE_O_BINDING_DESCRIPTION',
      image: 'wireo.png',
    },
    {
      availableBindings: [BookBinding.STITCH],
      id: BookMontage.STITCH,
      name: 'TYPE.STITCH_BINDING',
      description: 'TYPE.STITCH_BINDING_DESCRIPTION',
      image: 'saddlestitch.png',
    },
  ];
