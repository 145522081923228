import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {NgxsResetPluginModule} from 'ngxs-reset-plugin';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BindingModule} from './modules/binding/binding.module';
import {ContentModule} from './modules/content/content.module';
import {CoverModule} from './modules/cover/cover.module';
import {MainModule} from './modules/main/main.module';
import {OptionsModule} from './modules/options/options.module';
import {PaperModule} from './modules/paper/paper.module';
import {ProductModule} from './modules/product/product.module';
import {ProductState} from './modules/product/state/product.state';
import {PageNotFoundComponent} from './modules/shared/components/page-not-found/page-not-found.component';
import {SharedModule} from './modules/shared/shared.module';
import {AppConfigState} from './modules/shared/state/gui/app-config.state';
import {SummaryModule} from './modules/summary/summary.module';
import {BindingState} from './modules/binding/state/binding.state';
import {PaperState} from './modules/paper/state/paper.state';
import {ConfigurationState} from './modules/shared/state/configuration/configuration.state';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ContentState} from './modules/content/state/content.state';
import {OptionState} from './modules/options/state/option.state';
import {SurfaceModule} from './modules/surface/surface.module';
import {SurfaceState} from './modules/surface/state/surface.state';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';

import {environment} from '../environments/environment';
import {CoverState} from './modules/cover/state/cover.state';
import {PriceState} from './modules/shared/state/price/price.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {DeviceDetectorModule} from 'ngx-device-detector';
import { PriceCalculatorModule } from './modules/price-calculator/price-calculator.module';
import { RouterModule } from '@angular/router';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
  imports: [
    BindingModule,
    BrowserModule,
    ContentModule,
    CoverModule,
    HttpClientModule,
    MainModule,
    OptionsModule,
    PaperModule,
    ProductModule,
    SurfaceModule,
    SummaryModule,
    NgxsModule.forRoot([
      AppConfigState,
      ContentState,
      ConfigurationState,
      CoverState,
      ProductState,
      BindingState,
      PaperState,
      SurfaceState,
      OptionState,
      PriceState,
    ]),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production || environment.staging
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    PriceCalculatorModule,
  ],
    providers: [{provide: LOCALE_ID, useValue: 'de'}],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDe);
        registerLocaleData(localeFr);

    }
}
