
import {BookErrorsEnum} from '../enums/book-errors.enum';
import {BookInterface} from './book.interface';

export interface BookValidateReturnInterface {
  errors: BookErrorInterface[];
  book: BookInterface;
}

export interface BookErrorInterface {
  id: BookErrorsEnum;
  placeholderValues?: string[];
  source?: BookErrorSource;
}

export enum BookErrorSource {
  BACKEND,
  FRONTEND
}
