import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './containers/footer/footer.component';
import { HeaderComponent } from './containers/header/header.component';
import { InnerContainerComponent } from './containers/inner-container/inner-container.component';
import { MainContainerComponent } from './containers/main-container/main-container.component';


@NgModule({
  declarations: [
    HeaderComponent,
    MainContainerComponent,
    FooterComponent,
    InnerContainerComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    SharedModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    MainContainerComponent,
  ],
})
export class MainModule {
}
