import {Component, OnInit, Input, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import {ConfigurationState} from '../../state/configuration/configuration.state';
import {INTENTS} from '../../../../../../../shared/enums/intents.enum';
import {PagePreview} from './page-preview';
import {TranslateService} from '@ngx-translate/core';

const zoomConfig = {
    default: 3.1,
    max: 4.1,
    min: 1.1,
    step: 1
};


@Component({
    selector: 'bubu-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {

    @Input()
    pageCount: number;

    @Input()
    sourceWidthMM: number;

    @Input()
    sourceHeightMM: number;

    @Input()
    intent: string;

    @Input()
    projectId: string;

    @Input()
    colorMode: string;

    @Input()
    logo = false;

    @Input()
    doublePages = true;

    currentZoom = zoomConfig.default;
    fullScreenMode = false;

    private pagesChunksCount = 10;
    private loadedPages = 0;
    private nextDoublePageLocation = 'left';
    private currentPage = null;

    private container: HTMLElement;
    private imgPath: string;
    private previewConfig: any;
    private pdfPageCount: number;
    private initialized: boolean;
    private drawInnerBackCover: boolean;

    private pages: PagePreview[];

    private previewOptionsSubscription: Subscription;

    @ViewChild('loadMoreButton', {static: false}) loadMoreButton: ElementRef;

    constructor(private readonly apiService: ApiService,
                private readonly store: Store,
                private translate: TranslateService) {
        this.initialized = false;
    }

    static buildThumbnailURL(projectId: string, intent: INTENTS, pageNumber: number, pageCount: number) {
        const page = PreviewComponent.pad(pageNumber, pageCount.toString().length);
        return `${environment.backendUrl}/pdf/thumbnail/${projectId}/${intent}/${page}`;
    }

    static pad(n, width, z = '0') {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    ngOnChanges(changes) {
        this.container = document.getElementById('preview');

        if (changes.pageCount) {
            this.reset();
        }

        if (!this.initialized && this.projectId && this.pageCount && this.sourceWidthMM && this.sourceHeightMM) {
            this.imgPath = `${environment.backendUrl}/pdf/thumbnail/${this.projectId}/${this.intent}/`;
            this.pdfPageCount = this.pageCount; // pageCount could be manipulated in future progress
            this.pages = [];
            if (this.loadMoreButton) {
                this.loadMoreButton.nativeElement.style.display = 'inline-block';
            }
            this.drawViewer();
            this.initialized = true;
        }
    }

    private drawViewer() {
        this.clearContainer();
        this.previewOptionsSubscription = this.apiService.previewOptions(
            this.store.selectSnapshot(ConfigurationState.getBook)
        ).subscribe((previewConfig: any) => {
            // extract mm values from book size
            switch (this.intent) {
                case INTENTS.CONTENT:
                    this.previewConfig = previewConfig.content;
                    this.addContent();
                    break;
                case INTENTS.COVER:
                    this.previewConfig = previewConfig.cover;
                    switch (this.pdfPageCount) {
                        case 4:
                            this.addFourPageCover();
                            break;
                        case 2:
                            this.addTwoPageCover();
                            break;
                        default:
                            this.addContent();
                            break;
                    }
                    break;
                default:
                    throw new Error(`Invalid intent "${this.intent}"`);
            }

        });
    }

    addContent() {
        this.nextDoublePageLocation = 'left';
        this.currentPage = null;
        this.loadedPages = 0;

        if (this.previewConfig.hasEndleaf) {
            this.currentPage = new PagePreview(
                this.sourceWidthMM,
                this.sourceHeightMM,
                this.previewConfig.width,
                this.previewConfig.height,
                this.previewConfig.bleed,
                true,
                this.currentZoom,
                1
            );
            this.pages.push(this.currentPage);
            this.pageCount++;
            this.nextDoublePageLocation = 'right';
        } else if (this.intent === 'content' && this.previewConfig.showInnerCoverPages) {
            // inner front and inner back cover must be printed then
            this.drawInnerBackCover = true;
            this.currentPage = new PagePreview(
                this.sourceWidthMM,
                this.sourceHeightMM,
                this.previewConfig.width,
                this.previewConfig.height,
                this.previewConfig.bleed,
                true,
                this.currentZoom,
                2,
                this.translate.instant('PREVIEW.PAGE-IN-FRONT')
            );
            this.pages.push(this.currentPage);
            this.currentPage.addPageImage(
                this.imgPath.replace('content', 'cover') + '2',
                this.nextDoublePageLocation
            );
            this.nextDoublePageLocation = 'right';
        }

        // load first x images
        this.loadMoreButton.nativeElement.style.display = 'none';
        const loadTo = (this.loadedPages + this.pagesChunksCount);
        this.addImages(1, loadTo > this.pdfPageCount ? this.pdfPageCount : loadTo);

    }

    addImages(from: number, to: number) {
        const greyScale = this.colorMode === '1c';
        for (let i = from; i <= to; i++) {

            // TODO check if single or double page preview
            if (!this.currentPage) {
                const imageCount = i === this.pdfPageCount && !this.drawInnerBackCover ? 1 : 2;
                const text = this.drawInnerBackCover && i === this.pdfPageCount ? this.translate.instant('PREVIEW.PAGE-IN-BACK') : '';
                this.currentPage = new PagePreview(
                    this.sourceWidthMM,
                    this.sourceHeightMM,
                    this.previewConfig.width,
                    this.previewConfig.height,
                    this.previewConfig.bleed,
                    this.doublePages,
                    this.currentZoom,
                    imageCount,
                    text
                );
                this.pages.push(this.currentPage);
            }

            this.currentPage.addPageImage(
                this.imgPath + PreviewComponent.pad(i, this.pageCount.toString().length),
                this.nextDoublePageLocation, greyScale,
                () => {
                    this.onPageImageLoad();
                }
            );

            if (i === this.pdfPageCount && !this.drawInnerBackCover) {
                this.finishPage();
                continue;
            }

            switch (this.nextDoublePageLocation) {
                case 'left':
                    this.nextDoublePageLocation = 'right';
                    break;
                case 'right':
                    this.finishPage();
                    break;
            }

        }

        if (this.loadedPages === this.pdfPageCount && !this.drawInnerBackCover) {
            this.finishPage();
        }
    }

    addFourPageCover() {
        this.currentPage = new PagePreview(
            this.sourceWidthMM,
            this.sourceHeightMM,
            this.previewConfig.width,
            this.previewConfig.height,
            this.previewConfig.bleed,
            true,
            this.currentZoom,
            2,
            this.translate.instant('PREVIEW.PAGE-BACK')
        );
        this.pages.push(this.currentPage);

        this.currentPage.addPageImage(
            this.imgPath + '4',
            'left'
        );

        this.currentPage.addPageImage(
            this.imgPath + '1',
            'right'
        );
        this.finishPage();
    }

    addTwoPageCover() {
        this.currentPage = new PagePreview(
            this.sourceWidthMM,
            this.sourceHeightMM,
            this.previewConfig.width,
            this.previewConfig.height,
            this.previewConfig.bleed,
            true,
            this.currentZoom,
            2,
            this.translate.instant('PREVIEW.PAGE-BACK')
        );
        this.pages.push(this.currentPage);

        this.currentPage.addPageImage(
            this.imgPath + '2',
            'left'
        );

        this.currentPage.addPageImage(
            this.imgPath + '1',
            'right'
        );
        this.finishPage();

    }

    onPageImageLoad() {
        this.loadedPages++;
        if (this.loadedPages % this.pagesChunksCount === 0) {
            const loadTo = (this.loadedPages + this.pagesChunksCount) > this.pdfPageCount
                ? this.pdfPageCount
                : (this.loadedPages + this.pagesChunksCount);
            const loadFrom = (this.loadedPages + 1) > loadTo
                ? loadTo
                : this.loadedPages + 1;

            if (loadTo !== loadFrom) {
                this.loadMoreButton.nativeElement.style.display = 'inline-block';
                this.loadMoreButton.nativeElement.setAttribute('data-from', String(loadFrom));
                this.loadMoreButton.nativeElement.setAttribute('data-to', String(loadTo));
            } else {
                // all images loaded
                this.finishPreview();
            }
        }
        if (this.loadedPages === this.pdfPageCount) {
            // all images loaded
            this.finishPreview();
        }
    }

    private finishPage() {
        this.container.insertBefore(this.currentPage.getCanvas(), this.loadMoreButton.nativeElement);
        this.currentPage = null;
        this.nextDoublePageLocation = 'left';
    }

    private finishPreview() {
        if (this.drawInnerBackCover === true) {
            this.drawInnerBackCover = false;
            this.pages.push(this.currentPage);
            this.currentPage.addPageImage(
                this.imgPath.replace('content', 'cover') + '3',
                this.nextDoublePageLocation
            );
            this.pageCount++;
            this.finishPage();
        }

        this.loadMoreButton.nativeElement.style.display = 'none';
    }

    loadMorePages(ev) {
        if (ev && this.loadMoreButton.nativeElement.style.display !== 'none') {
            this.loadMoreButton.nativeElement.style.display = 'none';
            const from = Number(this.loadMoreButton.nativeElement.getAttribute('data-from'));
            const to = Number(this.loadMoreButton.nativeElement.getAttribute('data-to'));
            this.addImages(from, to);
        }
    }

    zoomIn() {
        if (this.currentZoom < zoomConfig.max) {
            this.currentZoom += zoomConfig.step;
            this.setPageZoom();
        }
    }

    zoomOut() {
        if (this.currentZoom > zoomConfig.min) {
            this.currentZoom -= zoomConfig.step;
            this.setPageZoom();
        }
    }

    maximize() {
        this.fullScreenMode = true;
        const wrapper = document.getElementById('previewWrapper');
        document.documentElement.style.overflow = 'hidden';
        window.scrollTo(0, 0);
        document.body.appendChild(wrapper);
    }

    minimize() {
        const wrapper = document.getElementById('previewWrapper');
        const container = document.getElementById('previewContainer');
        container.appendChild(wrapper);
        document.documentElement.removeAttribute('style');
        this.fullScreenMode = false;
        this.initialized = false;
    }

    setPageZoom() {
        for (const page of this.pages) {
            page.applyZoom(this.currentZoom);
        }
    }

    ngOnDestroy() {
        this.reset();
    }

    reset() {
        this.minimize();
        this.clearContainer();
        if (this.previewOptionsSubscription) {
            this.previewOptionsSubscription.unsubscribe();
        }
        this.initialized = false;
    }

    clearContainer() {
        for (const child of Array.from(this.container.getElementsByTagName('CANVAS'))) {
            this.container.removeChild(child);
        }
    }

    ngOnInit() {

    }

}
