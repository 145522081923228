import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ProductTypeInterface } from '../../interfaces/product-type.interface';
import { ProductState } from '../../state/product.state';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import { SelectProductType } from '../../../shared/state/configuration/configuration.action';

@Component({
  selector: 'bubu-product-types-list',
  templateUrl: './product-types-list.component.html',
  styleUrls: ['./product-types-list.component.scss'],
})
export class ProductTypesListComponent implements OnInit, OnDestroy {
  @Select(ProductState.productTypes) productTypes$: Observable<ProductTypeInterface[]>;
  @Select(ConfigurationState.selectedProductType) selectedProductType$: Observable<ProductTypeInterface>;
  @Input() showDropDownProduct = false;
  selectedProductType: string;
  selectedProductTypeSubscription: Subscription;

  constructor(private readonly store: Store) {
    this.selectedProductTypeSubscription = this.selectedProductType$.subscribe(selectedProductType => {
       this.selectedProductType = selectedProductType ? selectedProductType.id : null;
    });
  }

  onProductTypeChanged(event: string) {
    this.store.dispatch(new SelectProductType(event));
  }

  ngOnDestroy() {
    this.selectedProductTypeSubscription.unsubscribe();
  }

  ngOnInit() {
  }

}
