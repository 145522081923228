import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AppConfigState } from '../../../shared/state/gui/app-config.state';
import { MainMenuEntriesInterface } from '../../../shared/state/gui/config/main-menu-entries';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SetUserConfiguration, ValidateBook } from '../../../shared/state/configuration/configuration.action';
import { ApiService } from '../../../shared/services/api.service';
import { ConfigurationState } from '../../../shared/state/configuration/configuration.state';
import {
  SetCartButtonEnabled,
  SetCartButtonVisible,
  SetNextButtonEnabled
} from '../../../shared/state/gui/app-config.actions';
import { RouteServiceService } from '../../../shared/services/route-service.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'bubu-inner-container',
  templateUrl: './inner-container.component.html',
  styleUrls: ['./inner-container.component.scss']
})
export class InnerContainerComponent implements OnInit, OnDestroy {
  @Select(AppConfigState.mainMenuEntries) mainMenuEntries$: Observable<MainMenuEntriesInterface[]>;
  @Select(AppConfigState.cartButtonEnabled) cartButtonEnabled$: Observable<boolean>;
  @Select(AppConfigState.cartButtonVisible) cartButtonVisible$: Observable<boolean>;
  @Select(AppConfigState.nextButtonEnabled) nextButtonEnabled$: Observable<boolean>;
  mainRoutes: MainMenuEntriesInterface[] = [];
  productId: string = null;
  cartButtonEnabled = false;
  cartButtonVisible = false;

  private actionSubscription: Subscription;
  private mainMenuEntriesSubscription: Subscription;
  private cartButtonVisibleSubscription: Subscription;
  private cartButtonEnabledSubscription: Subscription;
  private nextButtonEnabledSubscription: Subscription;
  private queryParamSubscription: Subscription;
  private urlSubscription: Subscription;

  @ViewChild('loader', { static: false }) loader: ElementRef;
  @ViewChild('loaderOverlay', { static: false }) loaderOverlay: ElementRef;

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly store: Store,
              private readonly actions$: Actions,
              private apiService: ApiService,
              private readonly routeService: RouteServiceService) {
    this.mainMenuEntriesSubscription = this.mainMenuEntries$.subscribe(routes => {
      this.mainRoutes = routes;
    });
    this.store.dispatch(new SetCartButtonVisible(false));
    this.cartButtonVisibleSubscription = this.cartButtonVisible$.subscribe(visible => {
      this.cartButtonVisible = visible;
    });
    this.cartButtonEnabledSubscription = this.cartButtonEnabled$.subscribe(enabled => {
      this.cartButtonEnabled = enabled;
    });
    this.nextButtonEnabledSubscription = this.nextButtonEnabled$.subscribe(enabled => {
      this.cartButtonEnabled = enabled;
    });
    // logic for checking if 'Weiter' Button is enabled or not
    this.actionSubscription = this.actions$.subscribe(action => {
      if (action.action.constructor.name !== 'SetNextButtonEnabled' && this.mainRoutes) {
        // get current route in main route context
        const currentNavIndex = this.mainRoutes
          .indexOf(this.mainRoutes
            .find(route => route.link === this.route.snapshot.url[0].path));
        if (currentNavIndex < this.mainRoutes.length - 1) {
          this.store.dispatch(new SetNextButtonEnabled(this.routeService.checkIfRouteCanBeActivated(this.mainRoutes[currentNavIndex + 1].link)));
        }
      }
    });
  }

  ngOnInit() {
    this.queryParamSubscription = this.route.queryParamMap.pipe(map(params => params.get('projectId') || null))
      .subscribe(projectId => {
        this.productId = projectId;
        if (projectId) {
          this.store.dispatch(new SetUserConfiguration(projectId)).subscribe((data) => {
            if (data.configurationState.isCalculator) {
              this.router.navigate([environment.calculatorUrl]);
            } else {
              // hide cart button after loading configuration
              // because it is true when last visited page was summary
              this.urlSubscription = this.route.url.subscribe(url => {
                if (!url[0].path.includes('summary')) {
                  this.store.dispatch(new SetCartButtonVisible(false));
                }
              });
            }
          });
        }
      });
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
    this.mainMenuEntriesSubscription.unsubscribe();
    this.cartButtonVisibleSubscription.unsubscribe();
    this.cartButtonEnabledSubscription.unsubscribe();
    this.nextButtonEnabledSubscription.unsubscribe();
    this.queryParamSubscription.unsubscribe();
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  navigateToNextStep() {
    const currentNavIndex = this.mainRoutes
      .indexOf(this.mainRoutes
        .find(route => route.link === this.route.snapshot.url[0].path));
    if (currentNavIndex < this.mainRoutes.length - 1) {
      this.router.navigate([this.mainRoutes[currentNavIndex + 1].link]);
    }
  }

  async navigateToCart(event) {
    if (this.cartButtonEnabled === true) {
      // disabled cart button and show loader + overlay
      this.cartButtonEnabled = false;
      this.loader.nativeElement.style.display = 'inline';
      this.loaderOverlay.nativeElement.style.display = 'block';

      await this.apiService.saveUserConfig(this.store.snapshot()).toPromise();
      const book = this.store.selectSnapshot(ConfigurationState.getBook);
      const cartId = localStorage.getItem('cartId') || '0';
      this.apiService.finishCart(cartId, book).subscribe((result: any) => {

        if (result.errors) {
          // set cart button disabled and validate again to show user the errors
          this.store.dispatch(new SetCartButtonEnabled(false));
          this.store.dispatch(new ValidateBook());
          return;
        }

        localStorage.setItem('cartId', result.id);

        if (result.url) {
          window.location.href = result.url;
        }
      });
    }
  }
}
