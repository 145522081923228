import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { BubuButtonComponent } from './components/button/button.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { PriceComponent } from './components/price/price.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectionsComponent } from './components/selections/selections.component';
import { HeadingComponent } from './components/heading/heading.component';
import { MatSelectModule } from '@angular/material/select';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ProjectIdInterceptor } from './interceptors/project-id.interceptor';
import { PdfInfoComponent } from './components/pdf-info/pdf-info.component';
import { InfoListComponentComponent } from './components/info-list-component/info-list-component.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatInputModule } from '@angular/material/input';
import { PdfErrorComponent } from './components/pdf-error/pdf-error.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CoverModeInterceptor } from './interceptors/cover-mode.interceptor';
import { NumberComponent } from './components/number/number.component';
import { SampleFilesComponent } from './components/sample-files/sample-files.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { BookErrorComponent } from './components/book-error/book-error.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ResetConfiguratorComponent } from './components/reset-configurator/reset-configurator.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PopUpDialogComponent } from './components/pop-up-dialog/pop-up-dialog.component';
import { QuantityComponent } from './components/quantity/quantity.component';
import { TaxComponent } from './components/tax/tax.component';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { MobileDetectionDialogComponent } from './components/mobile-detection-dialog/mobile-detection-dialog.component';
import { PageCountComponent } from './components/page-count/page-count.component';
import { Book, Calendar, Info, Paperclip, Truck, User } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';

const FeatherIcons = {
  Book,
  Calendar,
  Info,
  Paperclip,
  Truck,
  User
};


@NgModule({
  declarations: [
    BubuButtonComponent,
    FileDropComponent,
    PriceComponent,
    SelectionsComponent,
    AdditionalInformationComponent,
    LanguageSwitcherComponent,
    RadioComponent,
    CheckboxComponent,
    NumberComponent,
    HeadingComponent,
    PdfInfoComponent,
    InfoListComponentComponent,
    PdfErrorComponent,
    SampleFilesComponent,
    UploaderComponent,
    BookErrorComponent,
    PreviewComponent,
    ResetConfiguratorComponent,
    MobileDetectionDialogComponent,
    PageCountComponent,
    PopUpDialogComponent,
    QuantityComponent,
    TaxComponent
  ],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    FeatherModule.pick(FeatherIcons),
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    NgxFileDropModule,
    TranslateModule,
    PdfJsViewerModule,
    InViewportModule
  ],
  exports: [
    AngularSvgIconModule,
    FeatherModule,
    NgxFileDropModule,
    FormsModule,
    BubuButtonComponent,
    FileDropComponent,
    TranslateModule,
    BubuButtonComponent,
    FileDropComponent,
    PriceComponent,
    SelectionsComponent,
    AdditionalInformationComponent,
    LanguageSwitcherComponent,
    RadioComponent,
    CheckboxComponent,
    NumberComponent,
    HeadingComponent,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    InfoListComponentComponent,
    PdfInfoComponent,
    PdfJsViewerModule,
    PdfErrorComponent,
    SampleFilesComponent,
    UploaderComponent,
    BookErrorComponent,
    PreviewComponent,
    ResetConfiguratorComponent,
    MobileDetectionDialogComponent,
    PopUpDialogComponent,
    TaxComponent,
    QuantityComponent,
    PageCountComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true, deps: [Store] },
    { provide: HTTP_INTERCEPTORS, useClass: ProjectIdInterceptor, multi: true, deps: [Store] },
    { provide: HTTP_INTERCEPTORS, useClass: CoverModeInterceptor, multi: true, deps: [Store] },
  ],
  entryComponents: [PopUpDialogComponent]
})

export class SharedModule {
}
