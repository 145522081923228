import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentContainerComponent } from './containers/content-container/content-container.component';
import { ContentRoutingModule } from './content-routing.module';
import { ColorModeComponent } from './components/color-mode/color-mode.component';
import { CoverModeComponent } from './components/cover-mode/cover-mode.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ContentContainerComponent, ColorModeComponent, CoverModeComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    SharedModule
  ],
  exports: [ColorModeComponent],
})
export class ContentModule {
}
