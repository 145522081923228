import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AddError, ValidateBook } from './modules/shared/state/configuration/configuration.action';
import { BookErrorsEnum } from '../../../shared/enums/book-errors.enum';
import { BookErrorSource } from '../../../shared/interfaces/book-validate-return.interface';
import { ContentState } from './modules/content/state/content.state';
import { CoverState } from './modules/cover/state/cover.state';
import { AppConfigState } from './modules/shared/state/gui/app-config.state';
import { environment } from '../environments/environment';

@Component({
  selector: 'bubu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'pdf2book frontend';
  gaTrackingID: string;
  isPriceCalculator = environment.priceCalculator || false;

  constructor(private translate: TranslateService,
              private readonly store: Store,
              private readonly router: Router) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(this.store.selectSnapshot(AppConfigState.selectedLanguage));

    // Google Analytics
    this.gaTrackingID = environment.gaTrackingID;

    if (this.gaTrackingID) {
      this.loadGoogleAnalytics();
      //  make google analytics able to track page view load as i mentioned above in the introduction
      this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
          }
        }
      );
    }

    // initial validate request
    this.store.dispatch(new ValidateBook());

    // set these errors initial for a correct view of the configurator
    if (!this.store.selectSnapshot(ContentState.isContentPDFSet)) {
      this.store.dispatch(new AddError({
        id: BookErrorsEnum.CONTENT_PDF_MISSING,
        source: BookErrorSource.FRONTEND
      }));
      this.store.dispatch(new AddError({
        id: BookErrorsEnum.INVALID_CONTENT_PAPER
      }));
    }

    if (!this.store.selectSnapshot(CoverState.isCoverPDFSet)) {
      this.store.dispatch(new AddError({
        id: BookErrorsEnum.COVER_PDF_MISSING,
        source: BookErrorSource.FRONTEND
      }));

    }

  }

  loadGoogleAnalytics(): void {
    const gaScript1 = document.createElement('script');
    gaScript1.innerText = `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;ga(\'create\', \'${this.gaTrackingID}\', \'auto\');`;

    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', 'https://www.google-analytics.com/analytics.js');

    document.documentElement.firstChild.appendChild(gaScript1);
    document.documentElement.firstChild.appendChild(gaScript);
  }

}
