import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Select} from '@ngxs/store';
import {ConfigurationState} from '../../state/configuration/configuration.state';
import {BOOK_MODULE_ERRORS} from '../../../../../../../shared/config/book-module-errors';
import {Observable, Subscription} from 'rxjs/index';
import {BookErrorsEnum} from '../../../../../../../shared/enums/book-errors.enum';
import {BookErrorInterface} from '../../../../../../../shared/interfaces/book-validate-return.interface';


@Component({
    selector: 'bubu-book-error',
    templateUrl: './book-error.component.html',
    styleUrls: ['./book-error.component.scss']
})
export class BookErrorComponent implements OnInit, OnDestroy {

    @Input()
    modules = [];

    errors = [];
    @Select(ConfigurationState.getErrors) bookErrors$: Observable<BookErrorInterface[]>;

    errorSubscription: Subscription;

    @Output()
    bookErrorsChange: EventEmitter<BookErrorInterface[]> = new EventEmitter<BookErrorInterface[]>();

    static filter(modules, configurationErrors): BookErrorInterface[] {
        const returnErrors = [];
        for (const moduleErrors of BOOK_MODULE_ERRORS) {
            for (const module of modules) {
                if (moduleErrors.id === module) {
                    moduleErrors.errors.map(err => {
                      if (configurationErrors){
                        const error = configurationErrors.find(error => error.id === err);
                        if (error) {
                            returnErrors.push(error);
                        }
                    }
                    });
                }
            }
        }

        return returnErrors;
    }

    constructor() {
    }

    replacePlaceholders(translatedText: string, placeholders: []) {
        if (placeholders) {
           return translatedText.replace(/\$[0-9]+/gmi, (match) => {
                return placeholders[Number(match.replace(/\$/, '')) - 1];
            });
        }
        return translatedText;
    }

    ngOnInit() {
        this.errorSubscription = this.bookErrors$.subscribe(err => {
            this.errors = [];
            for (const error of BookErrorComponent.filter(this.modules, err)) {
                if (!this.errors.includes(error)) {
                    this.errors.push(error);
                }
            }
            this.bookErrorsChange.emit(this.errors);
        });
    }

    ngOnDestroy() {
        this.errorSubscription.unsubscribe();
    }


}
