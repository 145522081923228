import { Component, OnInit } from '@angular/core';
import {Store} from '@ngxs/store';
import {SetOptions} from '../../state/option.actions';

@Component({
  selector: 'bubu-options-container',
  templateUrl: './options-container.component.html',
  styleUrls: ['./options-container.component.scss']
})
export class OptionsContainerComponent implements OnInit {

  constructor(private readonly store: Store) {
    store.dispatch(new SetOptions());
  }

  ngOnInit() {
  }

}
